import React, {Component} from "react";

import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'
import ClientDetails from './client-details'

const SuperPackageCard = ({booking}) => {

	return (
		<div>

			<ClientDetails booking={booking} showVenue={false} venue={false} />

			<div className="details card p-4">

				<div className="details__header">
					<h5 className="status-title">{booking.superpackage.name}</h5>
					{booking.status !== null ? (
						<div className="status-container">
							<div className="flex-card-container">
								<div className={`flex-item-2 status ${booking.status.type}`}>{booking.status.label}</div>
								<div className="flex-item-2">
									<span className="status__name">By {booking.status.by}</span>
									<span className="status__date">{booking.status.date}</span>
								</div>
							</div>
							<div className="status__message">
								<p>{booking.status.description}</p>
							</div>
						</div>
					) : null }
				</div>

				<div className="flex-card-container">
					<p className="flex-item-2"><Icon icon={ICONS.users} viewBox={ICONS.users.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">People</span></p>
					<p className="client-phone flex-item-2 text-right">{booking.superpackage.pax} people</p>
				</div>

				{booking.location ?
					<div className="flex-card-container">
						<p className="flex-item-2"><Icon icon={ICONS.location} viewBox={ICONS.location.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Location</span></p>
						<p className="client-phone flex-item-2 text-right">{booking.location}</p>
					</div>
				: null }

				{booking.superpackage.cost ?
					<div className="flex-card-container">
						<p className="flex-item-2">
							<Icon icon={ICONS.coin} viewBox={ICONS.coin.viewBox} width={'16'} height={'16'} color={'#000000'} />
							<span className="details__item">Price</span>
						</p>
						<p className="client-phone flex-item-2 text-right">
							{booking.superpackage.cost.currencyPrefix ? (
								<b><span dangerouslySetInnerHTML={{ __html: booking.superpackage.cost.currency}} />{booking.superpackage.cost.amount}</b>
							) : (
								<b>{booking.superpackage.cost.amount}<span dangerouslySetInnerHTML={{ __html: booking.superpackage.cost.currency }} /></b>
							)}
							{booking.superpackage.cost.isPerPerson ? " pp" : " total"}
						</p>
					</div>
				: null}

				<div className="flex-card-container package">
					<div className="flex-item-1">
						<table className="table table-condensed table-bordered">
							<thead>
								<tr>
									<th>
										<Icon icon={ICONS.boxes} viewBox={ICONS.boxes.viewBox} width={'16'} height={'16'} color={'#000000'} />
										Package Includes
									</th>
									<th>Date</th>
									<th>Time</th>
									<th>Pax</th>
								</tr>
							</thead>
							<tbody>
							<tr>
								<td>{booking.superpackage.hotel.name}</td>
								<td className="text-center">{booking.superpackage.date}</td>
								<td className="text-center">--</td>
								<td>{booking.superpackage.pax}</td>
							</tr>
							{booking.superpackage.events.map( (event) => (
								<tr key={`event-row-${event.id}`}>
									<td>{event.name}</td>
									<td>{event.date}</td>
									<td>{event.time}</td>
									<td>{booking.superpackage.pax}</td>
								</tr>
							))}
							</tbody>
						</table>
					</div>
				</div>

				<div>
					<div className="details__header mt-3">
						<h5 className="status-title">Notes</h5>
					</div>
					{booking.superpackage.notes.length > 0 ? <p className="client-phone" dangerouslySetInnerHTML={{ __html: booking.superpackage.notes}} /> : <p className="client-phone">No notes have been added</p>}
				</div>

			</div>

		</div>
	);
}

export default SuperPackageCard;
