import React, {useState, useEffect, useContext} from 'react';
import {usePost} from '../../../shared/hooks';
import { ICONS } from '../../shared/icons';
import Icon from '../../shared/icon-component';
import ReactTooltip from "react-tooltip";
import Alert from '../../shared/alert'
import {store} from "../../../context/store";

const PricingForm = ({eventId, schema, message}) => {

	const[globalData, setGlobalData] = useContext( store );

	let initialFormData = {
		firstname: globalData.firstname || '',
		surname: globalData.surname || '',
		email: globalData.email || '',
		notes: ''
	};

	if( schema !== null && schema.length > 0 ){
		schema.map((field) => {
			initialFormData[field.key] = field.default;
		})
	}

	const [formData, setFormData] = useState(initialFormData);
	const [formErrors, setFormErrors] = useState(null);
	const [ post, { posted, error, responseData, errorFields }, resetPost ] = usePost('/api/event/updatedataset' );

	const [showForm, setShowForm] = useState(true);
	const [success, setSuccess] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		if( validateForm() ){

			const postData = new FormData();
			postData.append('eventId', eventId);
			postData.append('formType', "pricing");
			postData.append('action', "request");
			postData.append("firstname", formData.firstname);
			postData.append("surname", formData.surname);
			postData.append("email", formData.email);
			postData.append("notes", formData.notes);

			schema.map( (field) => {
				if( typeof initialFormData[field.key] !== 'undefined' ){
					postData.append(field.key, formData[field.key]);
				}
			});

			post(postData);
		}

	}

	const validateForm = () => {

		let errors = [];

		if( !(formData.firstname.length > 0) ){
			errors.push('firstname');
		}

		if( !(formData.surname.length > 0) ){
			errors.push('surname');
		}

		if( !(formData.email.length > 0) ){
			errors.push('email');
		}

		schema.map( (field) => {
			if(!(typeof formData[field.key] !== 'undefined' && formData[field.key] > 0) ){
				errors.push(field.key);
			}
		})

		setFormErrors(errors);

		return errors.length==0;

	}

	useEffect( () => {
		if( responseData!==null && responseData.success ){
			setSuccess(responseData.success);
			setShowForm(false);
		}
	},[responseData])

	const fieldHasError = (key) => {
		if( formErrors !== null && formErrors.find( err => err==key ) ){
			return true;
		} else {
			return errorFields !== null && key === errorFields
		}
	}

	return (
		<div>

			<div className="details card">

				{message !== null ? (
					<div className="dataset__header">
						<h4 className="dataset__header--title">Message from the Eventa Group</h4>
						<div className='alert alert-info'>
							<div className="row">
								<div className="col-2 col-md-1">
									<Icon icon={ICONS.comments} viewBox={ICONS.comments.viewBox} width={'40'} height={'40'} color={'#2c3343'} />
								</div>
								<div className="col-10 col-md-11">
									<p dangerouslySetInnerHTML={{ __html: message.note}} />
									<p><small>Added - {message.date} {message.time}</small></p>
								</div>
							</div>
						</div>
					</div>
				) : null}

				{success ? (
					<Alert type='success' message={success}/>
				) : (
					<form onSubmit={handleSubmit}>

						<div className="amend-section">

							<div className="dataset__header">
								<h4 className="dataset__header--title"><Icon icon={ICONS.pencil} viewBox={ICONS.pencil.viewBox} width={'25'} height={'25'} color={'#2c3343'} /> Provide Requested Information</h4>
							</div>

							<div className="row">

								{schema.map( (field, index) =>
								<div className="col-sm-12 col-md-4" key={`product-price-field-${index}`}>
									<div className="form-group" key={`pricing-row-${index}`} >
										<label>{field.label} {field.tooltip ? <span className="info-icon" data-tip data-event='click focus' data-for={`ds-field-${index}`}><Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'20'} height={'20'} color={'#000000'} /></span> : null}</label>
										{field.required ? <span className="required">Required</span> :null}
										{field.tooltip ? (
											<ReactTooltip id={`ds-field-${index}`} place="top" type="light" globalEventOff='click' effect="solid" aria-haspopup='true'>
												<h5>{field.label}</h5>
												<p>{field.tooltip}</p>
											</ReactTooltip>
										) : null}
										<select className={fieldHasError(field.key) ? "form-control is-invalid" : "form-control"} defaultValue={field.default} onChange={(e)=>setFormData({...formData, [field.key]: e.target.value})}>
											{field.options.map( (option, index) => <option key={`option-item-${index}`} value={option.value}>{option.label}</option>)}
										</select>
									</div>
								</div>
								)}

								<div className="col">
									<div className="form-group">
										<label>Additional information</label>
										<textarea type="text" value={formData.notes} className="form-control" rows="5" onChange={(e) => setFormData({...formData, notes: e.target.value})} placeholder="To save time for all parties, please provide us with as many details as possible"/>
									</div>
								</div>

							</div>

							<div className="row">
								<div className="col-sm-12 col-md-6 col-lg-4">
									<div className="form-group">
										<label>Your firstname</label>
										<span className="required">Required</span>
										<input type="text" value={formData.firstname} className={fieldHasError("firstname") ? "form-control is-invalid" : "form-control"} onChange={(e) => setFormData({...formData, firstname: e.target.value}) }/>
									</div>
								</div>
								<div className="col-sm-12 col-md-6 col-lg-4">
									<div className="form-group">
										<label>Your surname</label>
										<span className="required">Required</span>
										<input type="text" value={formData.surname} className={fieldHasError("surname") ? "form-control is-invalid" : "form-control"} onChange={(e) => setFormData({...formData, surname: e.target.value}) }/>
									</div>
								</div>
								<div className="col-sm-12 col-md-6 col-lg-4">
									<div className="form-group">
										<label>Your email</label>
										<span className="required">Required</span>
										<input type="text" value={formData.email} className={fieldHasError("email") ? "form-control is-invalid" : "form-control"} onChange={(e) => setFormData({...formData, email: e.target.value}) }/>
									</div>
								</div>
							</div>

							<button className="btn btn-lg submit-btn" type="submit">
								{posted ? (
									<div className="btn-spinner-container">
										<div className="spinner-border" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>
								) : (
									'Submit'
								)}
							</button>

						</div>
					</form>
				)}

				</div>
			</div>
	)
}
export default PricingForm;
