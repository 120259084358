import React, {Component} from "react";

import { ICONS } from '../../shared/icons'
import Icon from '../../shared/icon-component'
import ReactTooltip from "react-tooltip";

const WebsiteContentCard = ({content, images, usps}) => {

	return (
		<div>

			{content.length > 0 ? (
				<div className="details card p-4 mt-3 mb-3">
					<div className="details__header">
						<h4 className="details__header--title">Website Content <span className="info-icon" data-tip data-event='click focus' data-for="website-content"><Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'25'} height={'25'} color={'#000000'} /></span></h4>
						<ReactTooltip id="website-content" place="top" type="light" globalEventOff='click' effect="solid" aria-haspopup='true'>
							<h5>Website Content</h5>
							<p>This section shows the title and description of the product for each site it appears on</p>
						</ReactTooltip>
					</div>

					<div className="flex-container">
						{content.map( (web, index) =>
							<div key={`content-item-${index}`} className="flex-item-2">
								<h5>{web.site}</h5>
								<p  dangerouslySetInnerHTML={{ __html: web.description}} />
							</div>
						)}
					</div>
				</div>

			) : null}

			{images.length > 0 ?
				<div className="details card p-4 mt-3 mb-3">
					<div className="details__header">
						<h4 className="details__header--title">Images <span className="info-icon" data-tip data-event='click focus' data-for="images"><Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'25'} height={'25'} color={'#000000'} /></span></h4>
						<ReactTooltip id="images" place="top" type="light" globalEventOff='click' effect="solid" aria-haspopup='true'>
							<h5>Images</h5>
							<p>We use these images on our website and customer emails</p>
						</ReactTooltip>
					</div>
					<div className="flex-container">
						{images.map( (image, index) =>
						<div key={`image-item-${index}`} className="flex-item-2">
							<h5>{image.company}</h5>
							<img src={`${image.path}?w=200&h=150`} alt={`This image will be used on ${image.company}`}/>
						</div>
						)}
					</div>
				</div>
			 : null}

			{usps.length > 0 ?
				<div className="details card p-4 mt-3 mb-3">
					<div className="details__header">
						<h4 className="details__header--title">USP's <span className="info-icon" data-tip data-event='click focus' data-for="usps"><Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'25'} height={'25'} color={'#000000'} /></span></h4>
						<ReactTooltip id="usps" place="top" type="light" globalEventOff='click' effect="solid" aria-haspopup='true'>
							<h5>USP's</h5>
							<p>The USP's are used on our website and are used by our sales team to help sell the product</p>
						</ReactTooltip>
					</div>

					<div className="flex-container">
						<div>
							<ul>
								{usps.map( (usp, index) => <li key={`usp-item-${index}`}>{usp}</li>)}
							</ul>
						</div>
					</div>
				</div>
			 : null }

		</div>
	);
}

export default WebsiteContentCard
