import React, { Component, useEffect, useState } from "react";
import { useFetch } from "../../shared/hooks"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DataTable from "../shared/datatable"
import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'
import { navigate } from "@reach/router";

const Bookings = () => {

  const { data, loading } = useFetch("/api/bookings", { current: [], cancelled: [], declined: [] } );

	let options = {
		filterType: 'dropdown',
		selectableRows: 'none',
		responsive: 'stacked',
		viewColumns: false,
		rowsPerPageOptions:	[5,10,15,20],
		textLabels: {
			body: {
				noMatch: "Sorry, no matching records found",
				columnHeaderTooltip: column => `Sort for ${column.label}`
			},
			pagination: {
				rowsPerPage: "Rows:",
				displayRows: "out of",
			}
		},
		onRowClick: (rowData) => {
		  navigate(`/requests/booking/${rowData[7]}/${rowData[9]}`);
		},
		setRowProps: (row) => {
			if( row[2].props.className === "pending status" ) {
				return {
					className: "pending-row"
				}
			}
		}
	};

	const columns = [
		{ label: 'ID',      name: "id", options: { filter: false, sort: true, display: false } },
		{ label: 'Ref',     name: "ref", options: { filter: false, sort: true,
				customBodyRender: (value, tableMeta, updateValue) => (
					<span className="booking-ref">{value}</span>
				)
			}},
		{ label: 'Status',  name: "statusType", options: { filter: true, sort: true,
				customBodyRender: (value, tableMeta, updateValue) => (
					<span className={value.toLowerCase() + ' status'}>{tableMeta.rowData[3]}</span>
				)}
		},
		{ label: 'StatusText',  name: "statusText", options: { filter: true, sort: true, display: false} },
		{ label: 'Date',    name: "date", options: { filter: false, sort: true } },
		{ label: 'Client',  name: "client", options: { filter: false, sort: true,
      customBodyRender: (value, tableMeta, updateValue) => (
        <span className="client-name">{value}</span>
      )
     }},
		{ label: 'Product', name: "product", options: { filter: false, sort: true,
      customBodyRender: (value, tableMeta, updateValue) => (
        <span className="product-name">{value}</span>
      )
     }},
		{ label: 'Type',    name: "type", options: { filter: true, sort: true, display: false  } },
		{ label: 'Location',name: "location", options: { filter: true, sort: true } },
		{ label: 'Request', name: "bookingItemId", options: { filter: false, sort: true, display: false  } }
	];

	return (
		<div className="bookings-wrap">

			<h1 className="section-h1">
				<Icon icon={ICONS.bookings} viewBox={ICONS.bookings.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
				Bookings
			</h1>

			<div className="inner-content">

				{loading ? (
          <div className="spinner-container">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
				) : (
					<Tabs>
						<TabList>
							<Tab>Current Bookings {data.current.length > 0 && <span className="badge">{data.current.length}</span>}</Tab>
							<Tab>Cancelled Bookings {data.cancelled.length > 0 && <span className="badge">{data.cancelled.length}</span>}</Tab>
							<Tab>Declined Bookings {data.declined.length > 0 && <span className="badge">{data.declined.length}</span>}</Tab>
						</TabList>
						<TabPanel>
							<DataTable title={"Current Bookings"} data={data.current} options={options} columns={columns} />
						</TabPanel>
						<TabPanel>
							<DataTable title={"Cancelled Bookings"} data={data.cancelled} options={options} columns={columns} />
						</TabPanel>
						<TabPanel>
							<DataTable title={"Declined Bookings"} data={data.declined} options={options} columns={columns} />
						</TabPanel>
					</Tabs>
				)}

			</div>

		</div>
	)
}

export default Bookings;
