
import React, { Component, useState, useEffect } from "react";
import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'
import Calendar from "../shared/calendar"

const CalendarView = () => {

	const [summaryData, setSummaryData] = useState(null);

	const updateSummaryData = (summaryData) => {

		let mySummaryData = {
			date: `${summaryData.daily.title}`,
			items: []
		};

		if(summaryData.daily.items.length > 0) {
			summaryData.daily.items.map( (it) =>{
				let temp = {
					id: it.bookingItemId,
					client: it.client,
					type: it.bookingItemType,
					name: it.name,
					date: it.date,
					ref: it.ref,
					location: it.location,
					status: it.status
				};
				mySummaryData.items.push(temp);
			})
		}

		setSummaryData(mySummaryData);

	};

	console.log({summaryData});

	return (
		<div className="calendar-wrap">
			<h1 className="section-h1">
				<Icon icon={ICONS.calendar} viewBox={ICONS.calendar.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
				Bookings Calendar
			</h1>
			<div className="inner-content">
				<div className="row">
					<div className="col-lg-12 col-xl-8">

						<Calendar view="large" updateSummary={updateSummaryData}/>

					</div>
					<div className="col-lg-12 col-xl-4">
						<div>
							{summaryData!==null ? (
							<div className="card calendar p-4">
								<h5><Icon icon={ICONS.calendar} viewBox={ICONS.calendar.viewBox} width={'25'} height={'25'} color={'#000000'} />{summaryData.date}</h5>
								{summaryData.items.length > 0 ?
									<>
									{summaryData.items.map( (item) => (
										<li key={item.id}>
											<strong>{item.ref} - {item.client}</strong>
											<p>{item.name}<br />{item.location}<br /></p>
											<label className={`status ${item.status.type} margin-bottom-5`}>{item.status.label}</label>
											<p><a href={"/requests/booking/" + item.type + "/" + item.id}>View <Icon icon={ICONS.chevronDown} viewBox={ICONS.chevronDown.viewBox} width={'20'} height={'20'} color={'#ed726f'} /></a></p>
										</li>
									))}
									</>
								: (
									<h3 className="pt-4">Nothing booked on this day</h3>
								)}

							</div>
							) : (
							<div className="card p-4">
								<p>Please click on a day containing bookings</p>
							</div>
							)}

						</div>
					</div>
				</div>

			</div>
		</div>
	)
}

export default CalendarView;
