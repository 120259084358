import React, { Component, useEffect, useState } from "react";
import { useFetch } from "../../shared/hooks"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DataTable from "../shared/datatable"
import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'
import { navigate } from "@reach/router";
import ReactTooltip from "react-tooltip";

const FlaggedBookingMessages = () => {

	let { data, loading } = useFetch( "/api/messages/flagged", {flagged: []} );


	let options = {
		filterType: 'dropdown',
		selectableRows: 'none',
		responsive: 'stacked',
		viewColumns: false,
		rowsPerPageOptions:	[5,10,15,20],
		textLabels: {
			body: {
				noMatch: "Good work! You have no messages require actioning",
				columnHeaderTooltip: column => `Sort for ${column.label}`
			},
			pagination: {
				rowsPerPage: "Rows:",
				displayRows: "out of",
			}
		},
		onRowClick: (rowData) => {
			navigate(`/requests/booking/${rowData[9]}/${rowData[11]}`);
		},
		setRowProps: (row) => {
			if( row[3].props.className === "pending status" ) {
				return {
					className: "pending-row"
				}
			}
		}
	};

	const columns = [
		{ label: 'ID',      name: "id", options: { filter: false, sort: true, display: false } },
		{ label: 'Ref',     name: "ref", options: { filter: false, sort: true,
			customBodyRender: (value, tableMeta, updateValue) => (
				<span className="booking-ref">{value}</span>
			)
		}},
		{ name:'lastUpdated', options: { display: false }},
		{ label: 'Last updated', name: "lastUpdatedTimestamp",
			options: {
				filter: false,
				sort: true,
				display: true,
				customBodyRender: (timestamp, tableMeta, updateValue) => {
					return <span>{tableMeta.rowData[2]}</span>
				}
			}
		},
		{ label: 'Status',  name: "statusType", options: { filter: true, sort: true,
			customBodyRender: (value, tableMeta, updateValue) => (
				<span className={value.toLowerCase() + ' status'}>{tableMeta.rowData[4]}</span>
			)}
		},
		{ label: 'StatusText',  name: "statusText", options: { filter: true, sort: true, display: false} },
		{ label: 'Date',    name: "date", options: { filter: false, sort: true } },
		{ label: 'Client',  name: "client", options: { filter: false, sort: true } },
		{ label: 'Product', name: "product", options: { filter: false, sort: true } },
		{ label: 'Type',    name: "type", options: { filter: true, sort: true, display: false  } },
		{ label: 'Location',name: "location", options: { filter: true, sort: true } },
		{ label: 'Request', name: "bookingItemId", options: { filter: false, sort: true, display: false  } }
	];

	const customTitle = () => (
		<React.Fragment>
			Flagged messages
			<span className="info-icon" data-tip data-for="flagged-message-tab">
				<Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'20'} height={'20'} color={'#000000'} />
			</span>
		</React.Fragment>
	);

	return (
		<div className="bookings-wrap">

			<h1 className="section-h1">
				<Icon icon={ICONS.comments} viewBox={ICONS.comments.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
				Flagged messages
			</h1>

			<div className="inner-content">

				{loading ? (
					<div className="spinner-container">
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				) : (
					<React.Fragment>

						<DataTable
							title={customTitle()}
							data={data.flagged} options={options} columns={columns} />

						<ReactTooltip id="flagged-message-tab" place="top" type="light" globalEventOff='click' effect="solid" aria-haspopup="true">
							<h5>Flagged messages</h5>
							<p>Flagged messages are messages you have marked that require further action.</p>
						</ReactTooltip>

					</React.Fragment>
				)}

			</div>

		</div>
	)
}

export default FlaggedBookingMessages;
