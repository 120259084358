import React from 'react';

const Icon = props => {

  const styles = {
    svg: {
      display: 'inline-block',
      verticalAlign: 'top',
      textAlign: 'center',
      marginRight: '10px'
    },
    g: {
      fill: props.color,
    },
  };

  return (
    <svg style={styles.svg} viewBox={props.viewBox} width={props.width} height={props.height}><g style={styles.g}>{props.icon.markup}</g></svg>
  );
};

export default Icon;
