import React, {useState} from "react";
import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'


const ClientDetails = ({booking, showVenue, venue}) => {
	
	return (
		<div className="details card p-4 mt-3 mb-3">
			<div className="details__header">
				<h5>Client Details</h5>
			</div>

			<div className="flex-card-container">
				<p><Icon icon={ICONS.user} viewBox={ICONS.user.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Client</span></p>
				<p className="client-name flex-item-2 text-right">{booking.client.name}</p>
			</div>

			{booking.client.phone ? (
				<div className="flex-card-container">
					<p><Icon icon={ICONS.phone} viewBox={ICONS.phone.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Phone</span></p>
					<p className="client-phone text-right flex-item-2"><small>{booking.client.phone}</small></p>
				</div>
			):null}

			{booking.client.email ? (
			<div className="flex-card-container">
				<p><Icon icon={ICONS.mail} viewBox={ICONS.mail.viewBox} width={'30'} height={'20'} color={'#000000'} /><span className="details__item">Email</span></p>
				<p className="client-phone text-right flex-item-2"><small>{booking.client.email}</small></p>
			</div>
			):null}

			{ booking.client.email || booking.client.phone ? (
				<div className="flex-card-container">
					<p>
						Please note, the customer details are being shared, should you need to get hold of 
						the customer over the departure weekend. Please do not contact the customer prior to this
					</p>
				</div>
			):null}
			
			
			{showVenue ? (
			<>
			{venue ? (
				<div className="flex-card-container">
					<p className="flex-item-2"><Icon icon={ICONS.location} viewBox={ICONS.location.viewBox} width={'16'} height={'16'} color={'#000000'} /><strong>Location</strong></p>
					<p className="flex-item-2 text-right">{venue.map( (address, index) => <span key={`address-row-${index}`}>{address}<br /></span>)}</p>
				</div>
			):(
				<div className="flex-card-container">
					<p className="flex-item-2"><Icon icon={ICONS.location} viewBox={ICONS.location.viewBox} width={'16'} height={'16'} color={'#000000'} /><strong>Location</strong></p>
					<p className="flex-item-2 text-right">{booking.location}</p>
				</div>
			)}
			</>
			) : null}
			
			{booking.client.secondary ? ( <SecondaryDetails booking={booking} /> ) : null}

		</div>

	);
}

const SecondaryDetails = ({ booking }) => {
	
	const [ secondary, showSecondary ] = useState( false )

		
	return (
		<div>
			<div>
				<a onClick={() => showSecondary( !secondary )}>
					{secondary ? 'Hide secondary contact details' : 'Show secondary contact details'}
				</a>
			</div>
			{secondary ? (
				<div className="mt-3">
					<div className="flex-card-container">
						<p><Icon icon={ICONS.user} viewBox={ICONS.user.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Name</span></p>
						<p className="client-name flex-item-2 text-right">{booking.client.secondary.name}</p>
					</div>
		
					{booking.client.secondary.phone ? (
						<div className="flex-card-container">
							<p><Icon icon={ICONS.phone} viewBox={ICONS.phone.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Phone</span></p>
							<p className="client-phone text-right flex-item-2"><small>{booking.client.secondary.phone}</small></p>
						</div>
					):null}
		
					{booking.client.secondary.email ? (
					<div className="flex-card-container">
						<p><Icon icon={ICONS.mail} viewBox={ICONS.mail.viewBox} width={'30'} height={'20'} color={'#000000'} /><span className="details__item">Email</span></p>
						<p className="client-phone text-right flex-item-2"><small>{booking.client.secondary.email}</small></p>
					</div>
					):null}
				</div>
			) : null}
		</div>
	)
}

export default ClientDetails
