import React, { Component } from "react";
import {useFetch} from '../../shared/hooks';
import {navigate} from "@reach/router";
import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DataTable from "../shared/datatable"
import Collapsible from 'react-collapsible';

const Products = () => {

	let initialData = {confirmed:[], unconfirmed: [], review: []}

	const { data, loading } = useFetch( "/api/products/all", initialData );

	const goBack = () => {
		navigate(-1);
	};

	let options = {

		filterType: 'dropdown',
		selectableRows: 'none',
		responsive: 'stacked',
		viewColumns: false,
		rowsPerPageOptions:	[5,10,15,20],
		textLabels: {
			body: {
				noMatch: "Sorry, no matching records found",
				columnHeaderTooltip: column => `Sort for ${column.label}`
			},
			pagination: {
				rowsPerPage: "Rows:",
				displayRows: "out of",
			}
		},
		onRowClick: (rowData) => {
		  navigate(`/products/${rowData[4]}/${rowData[0]}`);
		},
		setRowProps: (row) => {
			if( row[2].props.className === "pending status" ) {
				return {
					className: "pending-row"
				}
			}
		}
	};

	const columns = [
		{ label: 'ID',      name: "id", options: { filter: false, sort: true, display: false } },
		{ label: 'Name',    name: "name", options: { filter: false, sort: true } },
		{ label: 'Status',  name: "status", options: { filter: true, sort: true,
				customBodyRender: (value, tableMeta, updateValue) => (
					<span className={value.toLowerCase() + ' status'}>{value}</span>
				)}
		},
		{ label: 'Location',name: "location", options: { filter: true, sort: true } },
		{ label: 'Type',name: "type", options: { filter: true, sort: true } }
	];

	return (
		<div className="products-wrap">

			<h1 className="section-h1">
				<Icon icon={ICONS.products} viewBox={ICONS.products.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
				Your products
			</h1>

			<div className="row">
				<div className="col-sm-12 col-md-9">
					<div className="inner-content">

						<a onClick={goBack} className="back-link"><Icon icon={ICONS.chevronLeft} viewBox={ICONS.chevronLeft.viewBox} width={'14'} height={'14'} color={'#2c3343'} /> Back</a>

						{data.unconfirmed.length==0 && data.confirmed.length==0 && data.review.length==0 ? (
							<div className="spinner-container">
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						) : (
							<div>
								<Tabs>
									<TabList>
										<Tab>Action Required {data.unconfirmed.length > 0 && <span className="badge">{data.unconfirmed.length}</span>}</Tab>
										<Tab>Under Review {data.review.length > 0 && <span className="badge">{data.review.length}</span>}</Tab>
										<Tab>On Sale{data.confirmed.length > 0 && <span className="badge">{data.confirmed.length}</span>}</Tab>
									</TabList>
									<TabPanel>
										<DataTable title={"Action Required"} data={data.unconfirmed} options={options} columns={columns} />
									</TabPanel>
									<TabPanel>
										<DataTable title={"Under Review"} data={data.review} options={options} columns={columns} />
									</TabPanel>
									<TabPanel>
										<DataTable title={"On Sale"} data={data.confirmed} options={options} columns={columns} />
									</TabPanel>
								</Tabs>
							</div>
						)}
					</div>
				</div>
				<div className="col-sm-12 col-md-3">
					<div className="inner-content explained">
						<h4>Product Status Explained</h4>
						<div className="action-required section">
							<h5>Action Required</h5>
							<i>This status requires your urgent attention</i>
							<Collapsible trigger="View More">
								<p>These products are currently on sale, but we've recently amended/added details that we need you to verify.</p>
								<p>This also includes products where a 6 monthly routine audit check is due. We ask you to verify the information we have stored for each of your products. Just to ensure that they are up to date.</p>
      				</Collapsible>
						</div>
						<div className="divider"></div>
						<div className="under-review section">
							<h5>Under Review</h5>
							<i>You don't need to do anything yet</i>
							<Collapsible trigger="View More">
								<p>These products are currently off sale because they are being edited and reviewed by our team. Once we've finished editing them you will recieve an email notification and the products will move to action required.</p>
      				</Collapsible>
						</div>
						<div className="divider"></div>
						<div className="on-sale section">
							<h5>On Sale</h5>
							<i>You don't need to do anything unless your product details change</i>
							<Collapsible trigger="View More">
								<p>These products are currently on sale and we're confident that the information stored is correct. If anything isn't right please flag the product for review.</p>
      				</Collapsible>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Products;
