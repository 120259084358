import React, { Component, useContext, useEffect } from "react";
import { store } from "../../context/store";

import logoEventa from '../../../../img/group79.svg';
import logoHen from '../../../../img/group98.svg';
import logoStag from '../../../../img/group101.svg';

import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'
import { useState } from "react";
import {usePost} from "../../shared/hooks";
import { navigate } from "@reach/router";

const Header = () => {

	const [ state, setState ] = useContext( store )

	return (
		<header>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-lg-6 col-xl-6 pl-1">
						<div className="logos-container">
							<a href="/">
							<img className="logo eventa" alt="The Eventa Group" src={logoEventa} />
							<img className="logo stag" alt="The Eventa Group" src={logoStag} />
							<img className="logo hen" alt="The Eventa Group" style={{marginTop: '-15px'}} src={logoHen} />
							</a>
						</div>
					</div>
					<div className="col-12 col-lg-6 col-xl-6 pl-1">
						<div className="header-functions-wrap">
							<SearchInput />

							<div className="login-container d-none d-lg-block text-white">
								<div className="dropdown-toggle">
									<span><Icon icon={ICONS.user} viewBox={ICONS.user.viewBox} width={'16'} height={'16'} color={'#ffffff'} />Hi {state.name}</span>
								</div>
								<div className="dropdown-panel">
									<a href="/users/logout">Log out</a>
								</div>
							</div>
						</div>

					</div>

				</div>
			</div>
		</header>
	)
}

export default Header;


const SearchInput = () => {

	const [ searchInput, setSearchInput ] = useState('');
	const [ post, { posted, error, responseData, errorFields }, resetPost] = usePost('/api/bookings/search' );

	const doSearch = (e) => {
		e.preventDefault();

		let postData = new FormData();
		postData.append('search', searchInput);
		post(postData);
	}

	useEffect(()=>{
		if( responseData!==null ){
			navigate( '/search', {
				state: {
					searchResults: responseData.results,
					searchTerm: responseData.searchTerm,
					searchCount: responseData.resultCount
				}
			});

		}
	}, [responseData])

	return (
		<form onSubmit={doSearch}>
			<div className="search-wrap">
				<input className="form-control" type="text" placeholder="Search Bookings" onChange={(e) => setSearchInput(e.target.value)} value={searchInput} />
				<button type="submit" onClick={doSearch}><Icon icon={ICONS.search} viewBox={ICONS.search.viewBox} width={'16'} height={'16'} color={'#ffffff'} /></button>
			</div>
		</form>
	);
}
