import React, { createContext, useContext, useReducer } from "react";

const ApiErrorContext = React.createContext();
const initialState = { error: false,  message: null };

const reducer = (state, action) => {
   
    switch(action.type) {
      case "raise":
        return {
          error: true,
          message: action.message
        }
      case "reset":
          return initialState;
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
}

export const ApiErrorProvider = ({ children }) => {
    
    const [state, dispatch] = useReducer( reducer, initialState );
  
    return (
      <ApiErrorContext.Provider value={{state, dispatch}}>
        { state.error && ( 
            <div className="error">{state.message}</div> 
        )}
        {children}
      </ApiErrorContext.Provider>
    )
}
  
export const useApiError = () => useContext(ApiErrorContext);

export const handleApiError = () => {
    const {state, dispatch} = useContext(ApiErrorContext);
    return {
      error: state.error,
      message: state.message,
      raise: ( message ) => dispatch({ type: "raise", message: message }),
      reset: () => dispatch({ type: "reset" })
    }
}
