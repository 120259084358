import React from 'react';
import ReactTooltip from "react-tooltip";
import { ICONS } from '../../shared/icons'
import Icon from '../../shared/icon-component'

const ProductStatus = ({auditStatusId, type}) => {

	let className, title, leftText, description;

	switch( parseInt(auditStatusId) ){
		case 0:
			title = 'Pending';
			leftText = 'Under Review';
			className = 'review';
			description = () => {
				return (
					<p>This is a pending product</p>
				);
			}
			break;
		case 1:
			title = 'Flagged for review by us';
			leftText = 'Under Review';
			className = 'review';
			description = () => {
				return (
					<p>This is a product on review (by us)</p>
				);
			}
			break;
		case 2:
			title = 'Flagged for review by you';
			leftText = 'Under Review';
			className = 'review';
			description = () => {
				return (
					<p>This is a product on review (by you)</p>
				);
			}
			break;
		case 3:
			title = 'On Audit - Awaiting supplier signoff';
			leftText = 'Under Review';
			className = 'review';
			description = () => {
				return (
					<p>This is a product on audit</p>
				);
			}
			break;
		case 4:
			title = 'Awaiting supplier signoff';
			leftText = 'Action Required';
			className = 'required';
			description = () => {
				return (
					<p>This product just needs your signoff</p>
				);
			}
			break;
		case 5:
			title = 'On sale';
			leftText = 'On Sale';
			className = 'success';
			description = () => {
				return (
					<div>
						<p>This {type} is currently live. If anything is incorrect please flag the product for review
							at any time, using the <strong><a href="#review">review button</a></strong> at the bottom of
							the page
						</p>
					</div>
				)
			}
			break;
	}

	return (
		<div>
			<div className={`product-status ${className}`}>
				<p>
					<strong>{leftText}</strong>
					<span className="question-icon" data-tip data-event='click focus' data-for="product-status">
						<Icon icon={ICONS.question} viewBox={ICONS.question.viewBox} width={'25'} height={'25'} color={'#ffffff'} />
					</span>
					<span className="right-content">{title}</span>
				</p>
			</div>
			<ReactTooltip id="product-status" place="top" type="light" globalEventOff='click' effect="solid" aria-haspopup='true'>
				<h5>{title}</h5>
				{description()}
			</ReactTooltip>
		</div>
	)

}

export default ProductStatus;
