import React, { Component } from "react";
import {ICONS} from "../shared/icons";
import {Link} from "@reach/router";

const Footer = ({ name }) => {

	const year = new Date().getFullYear();

	return (
		<footer>
			<div className="container-fluid">
				<div className="row d-flex">
					<div className="col-xs-12 col-md-9 col-lg-7 order-xs-2 order-md-1">
						<h4>The Eventa Group - Partners Portal</h4>
						<h6>Delivering excellent customer service and offering high quality products and experiences.</h6>

						{/*
						<div className="stats-container row text-white">
							<div className="col-sm-12 col-md-4">
								<span className="number">1265</span>
								<p>Products</p>
							</div>
							<div className="col-sm-12 col-md-4">
								<span className="number">312</span>
								<p>Partners</p>
							</div>
							<div className="col-sm-12 col-md-4">
								<span className="number">7K</span>
								<p>Customers</p>
							</div>
						</div>
						*/}

						<p>&copy; The Eventa Entertainment Group LTD {year}</p>
				
					</div>
					<div className="col-xs-12 col-md-3 col-lg-5 order-xs-1 order-md-2">
						<h4>Useful Links</h4>
						<Link to="/faqs" className="d-flex">Help & FAQs</Link>
						<a href="/assets/docs/privacy-policy.pdf" target="_blank" className="d-flex">Privacy policy</a>
						<a href="/assets/docs/working-with-us.pdf" target="_blank" className="d-flex">Terms of working</a>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer;
