import React, { Component, useState, useEffect } from "react";
import {useFetch} from '../../shared/hooks';
import { navigate } from "@reach/router";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactTooltip from "react-tooltip";

import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'

import ImportantInfoCard from './shared/important-info-card'
import AdditionalInfoCard from './shared/additional-info-card'
import WebsiteContentCard from './shared/website-content-card'
import PriceCard from './shared/prices-card'
import ProductStatus from './shared/product-status'

import NormalForm from './shared/normal-form'
import FlagForReviewForm from './shared/flag-review-form'
import PricingForm from './shared/pricing-form'
import DatasetForm from './shared/dataset-form'

const ProductsEvent = ({id}) => {

	const [event, setEvent] = useState(null);
	const [form, setForm] = useState(null);
	const [message, setMessage] = useState(null);

	//api call will be as follows
	const { data, loading } = useFetch( `/api/event/view/${id}`, {
		event:      null,
		form:       null,
		message:    null
	});

	useEffect( () => {
		setEvent(data.event);
		setForm(data.form);
		setMessage(data.message);
	}, [data] );

	const renderForm = () => {
		if( form !==null && form.type.length > 0 ){
			switch( form.type ){
				case 'normal':
					return <NormalForm itemId={event.id} />
				case 'flagforreview':
					return <FlagForReviewForm itemId={event.id} itemType="event"/>
				case 'pricing':
					return <PricingForm eventId={event.id} schema={form.schema} message={message}/>
				case 'dataset':
					return <DatasetForm eventId={event.id} dataset={event.dataset} showOptions={form.showOptions} />
				default:
					return null;
			}
		}

		return null;

	}

	return (
		<div className="products-wrap product-item">

			<h1 className="section-h1">
				<Icon icon={ICONS.products} viewBox={ICONS.products.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
				Viewing event
			</h1>

			{event == null ? (
				<div className="spinner-container dash-stats">
					<div className="spinner-border" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			) : (
				<div className="inner-content">

					<div className="row">
						<div className="col-sm-6 col-md-7">
							<a onClick={()=>navigate(-1)} className="back-link"><Icon icon={ICONS.chevronLeft} viewBox={ICONS.chevronLeft.viewBox} width={'14'} height={'14'} color={'#2c3343'} /> Products</a>
							{event ? <> <span> / </span> <span>{event.name}</span> </> : null}
						</div>
						<div className="col-sm-6 col-md-5">
							{event !== null ? (
								<ProductStatus auditStatusId={event.auditStatusId} type="event"/>
							) : null}
						</div>
					</div>

					<div className="row">
						<div className="col-12">
							<Tabs>
								<TabList>
									<Tab>Important Info</Tab>
									<Tab>Current Prices</Tab>
									<Tab>Additional Info</Tab>
									<Tab>Website Content</Tab>
								</TabList>

								<TabPanel>
									<div className="row">
										<div className="col-12">
											<ImportantInfoCard item={event} type="event"/>
										</div>
									</div>
								</TabPanel>

								<TabPanel>
									<div className="row">
										<div className="col-12">
											<PriceCard prices={event.prices} type="event"/>
										</div>
									</div>
								</TabPanel>

								<TabPanel>
									<div className="row">
										<div className="col-12">
											<AdditionalInfoCard dataset={event.dataset}/>
										</div>
									</div>
								</TabPanel>

								<TabPanel>
									<div className="row">
										<div className="col-12">
											<WebsiteContentCard content={event.website} images={event.images} usps={event.usps}/>
										</div>
									</div>
								</TabPanel>

							</Tabs>

						</div>
					</div>

					<div className="row">
						{form !== null ? (
							<div className="col-sm-12">
								{renderForm()}
							</div>
						) : null}
					</div>

				</div>
			)}


		</div>
	)
}

export default ProductsEvent;
