import React, {useState, useContext, useEffect} from "react";
import {useFetch, usePost} from '../../shared/hooks';
import {Link, navigate} from "@reach/router";
import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'
import { store } from "../../context/store";
import { handleApiError } from "../../context/api_error";
import Alert from '../shared/alert';

import {useTotals} from "../../context/totals";

//Import components relating to the booking
import ActivityCard from '../shared/actvity-card';
import HotelCard from '../shared/hotel-card';
import SuperPackageCard from '../shared/superpackage-card';
import Messaging from '../shared/messaging';
import FlightDetails from '../shared/flight-details';

//Import forms
import BookingForm from './shared/booking-form';

const BookingsRequestItem = ({bookingItemId, bookingItemType}) => {

	let viewUrl, updateUrl;

	const initialData = {
		booking: null,
		showForm: false,
		thread: {messages:[], flagged: false},
		allowReply: false
	};

	switch(bookingItemType){
		case 'event':
			viewUrl = `/api/bookingevent/view/${bookingItemId}`;
			updateUrl = `/api/bookingevent/update/${bookingItemId}`;
			break;
		case 'hotel':
			viewUrl = `/api/bookinghotel/view/${bookingItemId}`;
			updateUrl = `/api/bookinghotel/update/${bookingItemId}`;
			break;
		case 'superpackage':
			viewUrl = `/api/bookingsuperpackage/view/${bookingItemId}`;
			updateUrl = `/api/bookingsuperpackage/update/${bookingItemId}`;
			break;
	}

	const { data, loading } = useFetch( viewUrl, initialData );

	//set states
	const {totals, dispatch } = useTotals();
	const {raise, reset} = handleApiError();
	const [globalData, setGlobalData] = useContext( store );
	const [success, setSuccess] = useState('');
	const [showForm, setShowForm] = useState(false);
	const [reply, setReply] = useState('');
	const [file, setFile ] = useState( false )
	const [allowReply, setAllowReply] = useState(false);
	const [thread, setThread] = useState({messages:[], flagged:false});
	const showMessages = globalData.showMessages;

	//Watch for changes to initial data loads
	useEffect( () => {

		if( showMessages && data.thread ){
			setThread(data.thread);

			//If this is the first time they've read it
			if( data.thread.markedAsRead ){
				dispatch({type:"updateCount", key: "pendingMessages", count: totals.pendingMessages > 0 ? totals.pendingMessages-1: 0})
			}

		}

		if( data.showForm != showForm ){
			setShowForm(data.showForm )
		}

		if( data.allowReply != allowReply ){
			setAllowReply(data.allowReply);
		}

	},[data]);

	const goBack = () => {
		navigate(-1);
	};

	const loadFlightDetails = () => {

		let required = false;
		let details = null;

		switch(bookingItemType){
			case 'event':
				//required = data.booking.event.flightDetailsRequired;
				required = data.booking.flightDetailsRequired;
				if(required){
					//details = data.booking.event.flightDetails;
					details = data.booking.flightDetails;
				}
				break;
			case 'superpackage':
				required = data.booking.flightDetailsRequired;
				if(required){
					details = data.booking.flightDetails
				}
				break;
		}

		if( required ){
			return <FlightDetails details={details}/>
		} else {
			return null;
		}
	}

	const loadCard = (booking) => {
		switch(bookingItemType) {
			case 'event':
				return <ActivityCard booking={booking} />
			case 'hotel':
				return <HotelCard booking={booking} />
			case 'superpackage':
				return <SuperPackageCard booking={booking}/>
		}
	};

	const loadNotes = () => {

		let notes = '';

		switch(bookingItemType) {
			case 'event':
				notes = data.booking.event.notes;
				break;
			case 'hotel':
				notes = data.booking.hotel.notes;
				break;
			case 'superpackage':
				notes = data.booking.superpackage.notes;
				break;
		}

		return (
			<div className="details card p-2 p-md-4 mt-3 mb-4">
				<div className="details__header">
					<h5>Notes from the Eventa Group</h5>
				</div>
				<div className="flex-card-container">
					{notes.length > 0 ? <p className="client-phone" dangerouslySetInnerHTML={{ __html: notes}} /> : <p className="client-phone">No notes have been added</p>}
				</div>
			</div>
		)
	};

	/****************************************
	 *  Handle booking request form reply   *
	 ****************************************/
	const [ post, { posted, error, responseData, errorFields }] = usePost( updateUrl );

	const handleSubmit = (formState) => {
		let postData = new FormData();
		postData.append("bookingId", data.booking.id);
		postData.append("bookingItemId", bookingItemId);
		postData.append("type", bookingItemType);
		postData.append("action", formState.action);
		postData.append("firstname", formState.firstname);
		postData.append("surname", formState.surname);
		postData.append("email", formState.email);
		postData.append("reason", formState.reason);
		post( postData );
	}

	//Watch for changes from request post
	useEffect( () => {
		if(responseData!==null && responseData.success ){
			setSuccess(responseData.success);
			setShowForm(false);
			setAllowReply(true);
			dispatch({type:"updateCount", key: "bookingRequests", count: totals.bookingRequests-1})
		}
		if( error !== null || responseData !== null ){
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		}
	},[responseData,error]);


	/****************************
	 *  Message reply           *
	 ****************************/
	const [ post2, replyResponse ] = usePost('/api/messages/add' );

	const handleReplySubmit = (e) => {

		e.preventDefault();

		reset();
		setSuccess('');

		let postData = new FormData();
		postData.append("bookingId", data.booking.id);
		postData.append("bookingItemId", bookingItemId);
		postData.append("type", bookingItemType);
		postData.append("message", reply);
		if( file ) {
			postData.append( 'file', file );
		}
		post2( postData );
	}

	const handleReplyChange = (value) => {
		setReply(value);
	}

	//Watch for changes to reply form
	useEffect( () => {
		//if we had a successfull response, then add message to our message list
		if( replyResponse.responseData !== null ){
			setSuccess('Your message has been added');
			setReply('');//reset reply textarea
			setThread( {...thread, messages: [...thread.messages, replyResponse.responseData.message ]});//append new message to list
		}
	},[replyResponse.responseData]);

	/****************************
	 *  Toggling message flags  *
	 ****************************/
	const [ toggleFlagPost, toggleFlagResponse ] = usePost('/api/messages/flag' );

	const handleToggleFlag = (e) => {
		e.preventDefault();
		let postData = new FormData();
		postData.append("bookingId", data.booking.id);
		postData.append("bookingItemId", bookingItemId);
		postData.append("type", bookingItemType);
		toggleFlagPost( postData );
	};

	//Watch for changes to reply form
	useEffect( () => {
		//if we had a successful response, then add message to our message list
		if( toggleFlagResponse.responseData !== null ){
			let newFlaggedStatus = toggleFlagResponse.responseData.isFlagged;
			setThread({...thread, flagged: newFlaggedStatus});
			dispatch({type: 'updateCount', key:'flaggedMessages', count: newFlaggedStatus ? totals.flaggedMessages+1 : totals.flaggedMessages-1 });
		}
	},[toggleFlagResponse.responseData]);

	return (
		<div className="booking-event-wrap">

			<h1 className="section-h1">
				<Icon icon={ICONS.availability} viewBox={ICONS.availability.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
				Booking: {data.booking !==null ? data.booking.ref : "" }
			</h1>

			{data.booking===null ? (
				<div className="spinner-container dash-stats">
					<div className="spinner-border" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			) : (
				<div className="inner-content">

					<a onClick={goBack} className="back-link"><Icon icon={ICONS.chevronLeft} viewBox={ICONS.chevronLeft.viewBox} width={'14'} height={'14'} color={'#2c3343'} /> Back</a>

					{success ? (
						<Alert type='success' message={success} close={()=>setSuccess(false)}/>
					) : null}

					<div className="row">
						<div className="col-sm-12 col-lg-12 col-xl-6">
							{loadCard(data.booking)}
						</div>
						<div className="col-sm-12 col-lg-12 col-xl-6">

							{loadFlightDetails()}

							{showForm ? (
								<React.Fragment>

									{loadNotes()}

									<BookingForm
										itemType={bookingItemType}
										isCancellation={data.booking.isCancellation}
										showOptions={!data.booking.isCancellation}
										errorFields={errorFields}
										doSubmitForm={handleSubmit}
										posted={posted}
									/>

								</React.Fragment>
							) : null}

							{showMessages ? (
								<Messaging
									flagged={thread.flagged}
									toggleFlagged={handleToggleFlag}
									file={file}
									setFile={setFile}
									handleSubmit={handleReplySubmit}
									handleChange={handleReplyChange}
									messages={thread.messages}
									disable={allowReply ? false : true}
									isSubmitted={replyResponse.posted}
									reply={reply}
									errors={replyResponse.error}
								/>
							):null}

						</div>
					</div>

				</div>
			)}

		</div>
	)

};
export default BookingsRequestItem;