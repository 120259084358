import React, { Component, useState, useEffect } from "react";
import ReactDOM from "react-dom";

//third party libs
import { Router, globalHistory } from "@reach/router"

//layout
import Header from "./components/layout/header"
import DesktopNavigation from "./components/layout/navigation/desktop"
import MobileNavigation from "./components/layout/navigation/mobile"
import Footer from "./components/layout/footer";


//individual pages
import Dashboard from "./components/dashboard"
import Confirmations from "./components/final_confirmations"
import Bookings from "./components/bookings";
import BookingsView from "./components/bookings/view";
import Requests from "./components/booking_requests"
import BookingsRequestItem from './components/booking_requests/booking-item';
import Availability from "./components/availability_requests";
import AvailabilityEvent from "./components/availability_requests/event";
import AvailabilityHotel from "./components/availability_requests/hotel";
import Products from "./components/products/index";
import ProductsEvent from "./components/products/event";
import ProductsHotel from "./components/products/hotel";
import ProductsSuperpackage from "./components/products/superpackage";
//import Messages from "./components/general_messages";
import BookingMessages from "./components/booking_messages"
import PendingBookingMessages from "./components/booking_messages/pending";
import FlaggedBookingMessages from "./components/booking_messages/flagged";
import CalendarView from "./components/calendar";
import Settings from "./components/settings/settings";
import Faq from "./components/faq";
import Search from "./components/search";

//error handling and state
import { ApiErrorProvider, handleApiError } from "./context/api_error";
import { StateProvider } from "./context/store";
import { TotalsProvider } from "./context/totals";

const App = ({ data }) => {

	const { reset } = handleApiError();

	useEffect(() => {
		return globalHistory.listen(() => {
				reset();
		});
	}, []);

	const [error, setGlobalError] = useState(false);
	const [success, setSuccess] = useState('');

	return (

		<StateProvider data={data}>
			<TotalsProvider>
				<div className="body-wrap">

					<Header />

					<div className="mobile-row d-sm-block d-lg-none">
						<div className="mobile-row__border"></div>
						<MobileNavigation /><span className="mobileMenu-text">Menu</span>
					</div>

					<div className="content container-fluid">
						<div className="row row-height">
							<div className="col-md-12 col-lg-3 col-xl-2 pl-0 h-100 sticky-top">
								<div className="desktop-nav d-none d-lg-block">
									<DesktopNavigation />
								</div>
							</div>

							<div className="col-md-12 col-lg-9 col-xl-10 h-100">

								<div className="main-content">
									<Router>
										<Dashboard path="/" />
										<Availability path="/requests/availability" />
										<AvailabilityEvent path="/requests/availability/event/:requestId" />
										<AvailabilityHotel path="/requests/availability/hotel/:requestId" />
										<Confirmations path="finalconfirmations" />
										<Requests path="/requests/booking" />
										<BookingMessages path="/messages" />
										<PendingBookingMessages path="/messages/pending" />
										<FlaggedBookingMessages path="/messages/flagged" />
										<BookingsView path="bookings/view/:bookingId" />
										<BookingsRequestItem path="/requests/booking/event/:bookingItemId" bookingItemType="event"/>
										<BookingsRequestItem path="/requests/booking/hotel/:bookingItemId" bookingItemType="hotel"/>
										<BookingsRequestItem path="/requests/booking/superpackage/:bookingItemId" bookingItemType="superpackage" />
										<Bookings path="bookings" />
										<ProductsEvent path="products/event/:id" />
										<ProductsHotel path="products/hotel/:id" />
										<ProductsSuperpackage path="products/superpackage/:id" />
										<Products path="products" />
										<CalendarView path="calendar" />
										<Settings path="settings" />
										<Search path="search" />
										<Faq path="faqs" />
									</Router>
								</div>
							</div>
						</div>
					</div>

					<Footer />

				</div>
			</TotalsProvider>
		</StateProvider>
	)
}

const wrapper = document.getElementById( "app" );

//supplier data
const element = document.querySelector('#cpt-app-data');
const json = element.textContent || element.innerText; // fallback for IE ≤ 8
const data = JSON.parse( json );

wrapper ? ReactDOM.render( <ApiErrorProvider><App data={data} /></ApiErrorProvider>, wrapper) : false;
