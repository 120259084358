import React, { Component, useEffect, useState } from "react";
import { handleApiError } from "../../context/api_error";

import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'


const Calendar = ({view, updateSummary}) => {

	const [calendarData, setCalendarData] = useState(null);
	const [calendarLoading, setCalendarLoading] = useState(true);
	const {error, message, raise, reset} = handleApiError();
	const [summaryType, setSummaryType] = useState('monthly');

	const fetchData = async ( url ) => {

		setCalendarLoading(true);
		reset();

		const response = await fetch( url );

		if( response.status == 200 ) {
			const data = await response.json();
			setCalendarLoading(false);
			setCalendarData(data);
		} else {
			switch( response.status ) {
				case 403:
					//forbidden
					history.pushState( null, null, '/' );
					window.location.replace( '/' );
					break;
				case 422:
					//std error
					const data = await response.json();
					raise( data.message );
					break;
				default:
					raise( 'sorry there has been an error' );
					break;
			}
		}
	};

	useEffect(() => {
		fetchData( '/api/calendar' );
	}, []);

	useEffect(() => {
		if( calendarData!==null ){

			let summaryData = {
				daily: {title: '', items: []},
				monthly: {title: calendarData.current, items: calendarData.summary}
			}

			for( let prop in calendarData.calendar) {
				if (Object.prototype.hasOwnProperty.call(calendarData.calendar, prop)) {
					if(calendarData.calendar[prop].currentDay){
						let activeDay = calendarData.calendar[prop];
						summaryData.daily.items = activeDay.items;
						summaryData.daily.title = activeDay.pretty;
					}
				}
			}
			updateSummary(summaryData, summaryType);

		}

	}, [calendarData]);

	//Helper to grab 'items' from calendar data
	const handleDayClick = (dayNumber) => {
		let newCalendarData = calendarData.calendar;
		for( let prop in newCalendarData) {
			if (Object.prototype.hasOwnProperty.call(newCalendarData, prop)) {
				if( newCalendarData[prop] !== false ){
					newCalendarData[prop].currentDay = dayNumber==newCalendarData[prop].number;
				}
			}
		}
		setSummaryType('daily');
		setCalendarData({...calendarData, calendar: newCalendarData});
	}

	const nextMonth = ( url ) => {
		setSummaryType('monthly');
		fetchData( url );
	}
	const prevMonth = ( url ) => {
		setSummaryType('monthly');
		fetchData( url );
	}

	return (
		<div className={`calendar calendar-` + view}>
			{calendarLoading || !calendarData ? (
				<div className="spinner-container">
					<div className="spinner-border" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			) : (
				<>
				<div className="calendar-header">
					<div className="calendar-btn-container d-flex mb-3">
						<button className="prev-btn" onClick={() => prevMonth(calendarData.prev)}><Icon icon={ICONS.chevronLeft} viewBox={ICONS.chevronLeft.viewBox} width={'16'} height={'16'} color={'#000000'} /> Prev </button>
						<h4 className="mx-auto">{calendarData.current}</h4>
						<button className="next-btn" onClick={() => nextMonth(calendarData.next)}>Next <Icon icon={ICONS.chevronLeft} viewBox={ICONS.chevronLeft.viewBox} width={'16'} height={'16'} color={'#000000'} /> </button>
					</div>
				</div>

				<table>
					<thead>
						{renderCalendarHeadings()}
					</thead>
					<tbody>
						{renderCalendarRows( calendarData, view, handleDayClick )}
					</tbody>
				</table>
				</>
			)}
		</div>
	)
}

const renderCalendarHeadings = () => {
	return (
		<tr className="calendar-heading">
			<td>Mon</td>
			<td>Tue</td>
			<td>Wed</td>
			<td>Thu</td>
			<td>Fri</td>
			<td>Sat</td>
			<td>Sun</td>
		</tr>
	)
}

const renderCalendarRows = ( data, view, handleDayClick ) => {

	const rows = [];
	let cells = [];
	let rowCount = 1;
	let cellCount = 1;

	if( data ) {

		Object.keys(data.calendar).map((day, index) => {

			const dayItem = data.calendar[day];

			if( !dayItem ) {
				cells.push( <BlankCell key={`cell-` + cellCount} /> );
			} else {
				cells.push( <Cell day={dayItem} key={`cell-` + cellCount} view={view} cellCount={cellCount} handleOnClick={() => handleDayClick(dayItem.number)} /> );
			}


			if( index%7 == 6 ) {
				rows.push(
					<tr key={`row-` + rowCount}>{cells}</tr>
				);
				rowCount += 1;
				cells = [];
			}
			cellCount += 1;

		})
	}
	return rows;
}

const BlankCell = () => (
	<td className="blank-cell">&nbsp;</td>
);



const Cell = ({ day, view, cellCount, handleOnClick }) => {

	let calendarClasses = ' day-cell';

	if(day.today) {
		calendarClasses += " today";
	}

	if(day.weekend) {
		calendarClasses += " weekend";
	}

	if(day.weekend && day.today) {
		calendarClasses = " day-cell today";
	}

	if( day.currentDay ){
		calendarClasses += " selected";
	}

	return (
		<td className={calendarClasses} onClick={handleOnClick} style={{cursor: 'pointer'}}>
			<p className="cal-day-number">{day.number}</p>
			<CellData view={view} data={day.items} cellCount={cellCount} />
		</td>
	)
}

const CellData = ({data, view, cellCount}) => {
	if( view == 'small' ) {
		return data.length > 0 ? <div className="cal-item-count"></div> : null;
	} else {
		if( data.length > 0 ) {
			return (
				<>
					{data.map((item, index) => (
						<span className={"cal-label "+ item.status.type} key={`cell-data-` + cellCount + '-' + index}><p>{item.ref}</p></span>
					))}
				</>
			)
		} else {
			return null
		}
	}
}

export default Calendar;
