import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import Alert from './alert';
import {usePost} from "../../shared/hooks";


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
		transform             : 'translate(-50%, -50%)',
    zIndex								: 9999,
    maxWidth              : '600px'
  }
};

Modal.setAppElement('#app');
 
const GDPRmodal = ({setRequireGDPR}) => {

	const [modalIsOpen,setIsOpen] = useState(true);
	const [displayWarning, setDisplayWarning] = useState(false);
	const [confirmed, setConfirmed] = useState(false);
	const [ post, { posted, error, responseData, errorFields }] = usePost('/api/settings/updategdpr' );

	function closeModal(){
		if( !confirmed ){
			setDisplayWarning(true);
		} else {
			let postData = new FormData();
			postData.append('action', 'confirm');
			post(postData);
		}
		return false;
	}

	useEffect( ()=>{
		if( responseData!==null && responseData.status == 200 ){
			setIsOpen(false);
			setRequireGDPR(false);
		}
	},[responseData]);
	
	return (
		<div>

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Example Modal"
			>

				{displayWarning ? (
					<Alert title="Important Information" message="Please take a moment to make sure you've read our Privacy Policy and Ts&Cs" type="error"/>
				):null}

				<h2>GDPR</h2>
				<p>New EU-wide data protection legislation came into effect on May 25th, 2018. The General Data Protection Regulation (GDPR) is a positive step towards individuals having more control over how their data is used and how they are contacted.</p>
				<p>We take our customer’s data privacy very seriously, so we’ve updated our Privacy Policy and our Terms of Working to reflect the new legislation. </p>
				<p>We need you – as part of our valued supply chain – to be treating their data in the same, careful way that we are, so it’s really important that you read the two documents carefully, then tick the box to let us know that you’re happy with what you’ve read.</p>
				<p>Remember that we are also applying the same rules internally, when we’re training our staff on how to handle your data too.</p>
				<p>If you have any queries or concerns, please send an email to <a href="mailto:gdpr@theeventagroup.com">gdpr@theeventagroup.com</a>. Thank you for your ongoing support!</p>
				<div className="form-group">
					<div className="form-check">
						<label className="form-check-label">
							<input className={displayWarning ? "form-check-input is-invalid" : "form-check-input"} type="checkbox" name="gdpr" onChange={(e) => setConfirmed(e.target.checked) } checked={confirmed}/>
							I have read and understood the <a href="/assets/docs/privacy-policy.pdf" target="_blank">Privacy Policy</a> and the <a href="/assets/docs/working-with-us.pdf" target="_blank">Terms of Working</a>
						</label>
						{displayWarning ? (
							<div className="invalid-feedback">
								You must agree before submitting.
							</div>
						) : null}
					</div>
				</div>

				<button onClick={closeModal} className="login-btn" type="button">
					{posted ? (
						<div className="btn-spinner-container">
							<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					): 'Continue'}
				</button>

			</Modal>
		</div>
	);
}

export default GDPRmodal;