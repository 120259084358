import React, { useContext, useState, useRef } from "react";
import { ICONS } from '../shared/icons';
import Icon from '../shared/icon-component';
import { store } from "../../context/store";

const Messaging = ({messages, disable, file, setFile, handleSubmit, handleChange, isSubmitted, reply, errors, flagged, toggleFlagged}) => {

	const [state, setState] = useContext( store )
	const inputRef = useRef()

	const messageList = messages.map( (msg) => {
		if( msg.isSupplier) {
			return <SupplierMessage key={`message-${msg.id}`} initials={state.initials} message={msg.body} added={msg.added} attachment={msg.attachment}/>
		} else {
			return <AdminMessage key={`message-${msg.id}`} message={msg.body} added={msg.added} isRead={msg.isRead} attachment={msg.attachment} />
		}
	});

	return (
		<div className="messages card mt-3 mb-3">
			<div className="messages__header p-4">
				{messageList.length > 0 ? (
					<div>
						{flagged ?
							<p className="text-success">
								<Icon icon={ICONS.flaggedMessage} viewBox={ICONS.flaggedMessage.viewBox} width={'25'} height={'25'} color={'#70d39c'} />
								flagged as important</p>
							:null}
						<button onClick={toggleFlagged} className={`flag ${flagged ? "flag-on" : "flag-off"}`}>{flagged ? "Unflag" : "Flag this booking"}</button>
					</div>
				):null}
				<h5>Message us about this booking</h5>
			</div>

			<div className="messages__pane">
				{messageList.length > 0 ? messageList : <p className="messages__none">No messages to display</p>}
			</div>

			{disable==false ? (
			<div className="messages__reply">
				<h5>Got a question about your booking?</h5>
				<form onSubmit={handleSubmit}>
					<input
						ref={inputRef}
						onChange={(e) => setFile( e.target.files[0] )}
						type="file"
						style={{ display: "none" }}
						// multiple={false}
					/>

					<div className="form-group">
						<div className="input-group">

							<button type="button" className="btn attach" onClick={() => inputRef.current.click()}>
								<Icon icon={ICONS.attach} viewBox={ICONS.attach.viewBox} width={'45'} height={'45'} color={'#2c3343'} />
							</button>

							<div className="textarea-container">
								<textarea className={errors ? "form-control is-invalid" : "form-control"} onChange={(e)=>handleChange(e.target.value)} placeholder='Your message...' value={reply}></textarea>

								{ file &&
									<div className="attached-file">{file.name} <a onClick={(e) => setFile(false)}><Icon icon={ICONS.closeButton} viewBox={ICONS.closeButton.viewBox} width={'20'} height={'20'} color={'#2c3343'} /></a></div>
								}
							</div>

							<button type="submit" className="btn submit">
								{isSubmitted ? (
									<div className="btn-spinner-container">
										<div className="spinner-border" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>
								) : (
									<Icon icon={ICONS.send} viewBox={ICONS.send.viewBox} width={'45'} height={'45'} color={'#2c3343'} />
								)}
							</button>
						</div>

						{errors ? <span className="error">Please make sure you have filled this out correctly</span> : null}



					</div>
				</form>
			</div>
			):null}

		</div>
	)

}

export default Messaging;

const SupplierMessage = ({initials, message, added, attachment}) => {
	return (
		<div>
			<div className="message outgoing">
				<div className="message__icon">
					<div className="message__avatar">{initials}</div>
				</div>
				<div className="message__container">
					<p className="message__text" dangerouslySetInnerHTML={{ __html: message }}></p>
					{attachment && <Attachment attachment={attachment} />}
					<span className="message__date">{added}</span>
				</div>
			</div>
		</div>
	)
}

const AdminMessage = ({message, added, isRead, attachment}) => {
	return (
		<div>
			<div className="message incoming">
				<div className="message__icon">
					<div className="message__avatar">SR</div>
				</div>
				<div className="message__container">
					<p className="message__text" dangerouslySetInnerHTML={{ __html: message }}></p>
					{attachment && <Attachment attachment={attachment} />}
					<span className="message__date">{added}</span>
				</div>
			</div>
		</div>
	)
}

const Attachment = ({ attachment }) => {
	return (
		<a href={`/messages/attachment/${attachment.itemId}/${attachment.id}`} target="_blank">{attachment.name}</a>
	)
}
