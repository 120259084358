import React, { createContext, useContext, useReducer, useEffect } from "react";
import {usePost} from "../shared/hooks";

const initialTotals = {
    total: 0,
    availabilityRequests: 0,
    finalConfs: 0,
    bookingRequests: 0,
    products: 0,
    pendingMessages: 0,
	flaggedMessages: 0,
    requireGDPR: false,
    loading: true
};

const TotalsContext = React.createContext( initialTotals );

const totalReducer = (totals, action) => {

	switch( action.type ){
		case "updateTotals":
			return {
				...totals,
				...action.data,
				loading: false
			};
		case "updateCount":
			return {
				...totals,
				[action.key]: action.count,
				loading: false
			};
		default:
			return totals;
	}
}

export const TotalsProvider = ({ children }) => {

	const [totals, dispatch] = useReducer( totalReducer, initialTotals );

	useEffect(() => {

		const fetchData = async () => {
			//console.log('loading totals');
			const response = await fetch( '/api/dashboard' );

			if( response.status == 200 ) {
				const data = await response.json();
				dispatch({ type: "updateTotals", data: data })
			}
		};

		//Run on init
		fetchData();

		//Refresh totals every 15 mins
		setInterval( () => {
			fetchData();
		},1000 * 60 * 15);

	}, []);

	return (
		<TotalsContext.Provider value={{totals, dispatch}}>
			{children}
		</TotalsContext.Provider>
	)
}
  
export const useTotals = () => useContext(TotalsContext);