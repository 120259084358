import React, {useContext} from 'react';
import { ICONS } from '../../../components/shared/icons'
import { useTotals } from "../../../context/totals";
import {store} from "../../../context/store";

export const navStructure = () => {

	const { totals, dispatch } = useTotals();
	const [globalData, setGlobalData] = useContext( store );


	{}
	let nav = [
		{
			key: "dashboard",
			path: "/",
			title: "Dashboard",
			icon: ICONS.dashboard,
			viewBox: ICONS.dashboard.viewBox,
		},
		{
			key: "toDo",
			path: "no-link",
			title: "TO DO",
			icon: '',
		},
		{
			key: "bookingRequests",
			path: "/requests/booking",
			title: "Booking Requests",
			icon: ICONS.bookingRequest,
			viewBox: ICONS.bookingRequest.viewBox,
			count: totals.bookingRequests
		},
		{
			key: "availabilityRequests",
			path: "/requests/availability",
			title: "Availability Requests",
			icon: ICONS.availability,
			viewBox: ICONS.availability.viewBox,
			count: totals.availabilityRequests
		},
		{
			key: "finalConfirmations",
			path: "/finalconfirmations",
			title: "Final confirmation",
			icon: ICONS.finalConfirmation,
			viewBox: ICONS.finalConfirmation.viewBox,
			count: totals.finalConfs
		},
		{
			key: "products",
			path: "/products",
			title: "Products",
			icon: ICONS.products,
			viewBox: ICONS.products.viewBox,
			count: totals.products
		},
		{
			key: "messagesTitle",
			path: "no-link",
			title: "MESSAGES",
			icon: ICONS.comments,
		},
		{
			key: "messagesPending",
			path: "/messages/pending",
			title: "Pending",
			icon: ICONS.comments,
			viewBox: ICONS.comments.viewBox,
			count: totals.pendingMessages
		},
		{
			key: "messagesFlagged",
			path: "/messages/flagged",
			title: "Flagged",
			icon: ICONS.flaggedMessage,
			viewBox: ICONS.flaggedMessage.viewBox,
			count: totals.flaggedMessages
		},
		{
			key: "messages",
			path: "/messages",
			title: "View all",
			icon: ICONS.mail,
			viewBox: ICONS.mail.viewBox,
			count: false
		},
		{
			key: "myAccount",
			path: "no-link",
			title: "MY ACCOUNT",
			icon: ".availability",
		},
		{
			key: "bookings",
			path: "/bookings",
			title: "Bookings",
			icon: ICONS.bookings,
			viewBox: ICONS.bookings.viewBox,
		},
		{
			key: "calendar",
			path: "/calendar",
			title: "Bookings Calendar",
			icon: ICONS.calendar,
			viewBox: ICONS.calendar.viewBox,
		},
		{
			key: "settings",
			path: "/settings",
			title: "Settings",
			icon: ICONS.settings,
			viewBox: ICONS.settings.viewBox,
		}
	];

	return nav;
}