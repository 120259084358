import React, {createContext, useState} from 'react';

const initialState = {};

const store = React.createContext([{}, () => {}]);
const { Provider } = store;

const StateProvider = ( { data, children } ) => {
    const [state, setState] = useState(data);
    return <Provider value={[ state, setState ]}>{children}</Provider>;
};

export { store, StateProvider }