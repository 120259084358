import React from 'react';
import { useFetch } from "../../shared/hooks"
import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'

const Faq  = () => {

	const { data, loading } = useFetch("/api/faq", {faqs: []} );

	return (
		<div className="bookings-wrap">

			<h1 className="section-h1">
				<Icon icon={ICONS.bookings} viewBox={ICONS.bookings.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
				Help and FAQs
			</h1>

			<div className="inner-content">
				{data.faqs.map( (faq) =>
					<div>
						<p>
							<b dangerouslySetInnerHTML={{ __html: faq.question }} />
						</p>
						<p dangerouslySetInnerHTML={{ __html: faq.answer }} />
					</div>
				)}
			</div>
		</div>

	)
}
export default Faq;