import React, { Component, useState, useEffect } from "react";
import { Link } from "@reach/router"
import { ICONS } from "../shared/icons"
import Icon from "../shared/icon-component"

import { useTotals } from "../../context/totals";

import Calendar from "../shared/calendar"
import GDPRmodal from '../shared/gdpr-modal'

const Dashboard = ({ setGlobalError, loading }) => {

	const {totals, dispatch } = useTotals();
	const [upcomingData, setUpcomingData] = useState(null);
	const [upcomingTitle, setUpcomingTitle] = useState('');
	const [requireGDPR, setRequireGDPR] = useState(false);
	const [summaryType, setSummaryType] = useState('monthly');

	const updateSummary = (data, type) => {

		setSummaryType(type);

		if( type == 'daily' ){
			setUpcomingData(data.daily.items);
			setUpcomingTitle(data.daily.title);
		} else {
			setUpcomingData(data.monthly.items);
			setUpcomingTitle(data.monthly.title);
		}

	}

	useEffect( () => {
		if( totals.requireGdpr ){
			setRequireGDPR(true);
		}
	},[totals]);

	return (
		<div className="dashboard-wrap">
			<h1 className="section-h1"><Icon icon={ICONS.dashboard} viewBox={ICONS.dashboard.viewBox} width={'46'} height={'46'} color={'#ffffff'} />Dashboard</h1>

			<div className="row">

				<div className="col-sm-12 col-md-6 col-lg-3">
					<DashboardCard title="Booking Requests" value={totals.bookingRequests} path="/requests/booking" icon={ICONS.bookingRequest} loading={totals.loading} />
				</div>

				<div className="col-sm-12 col-md-6 col-lg-3">
					<DashboardCard title="Availability Requests" value={totals.availabilityRequests} path="/requests/availability" icon={ICONS.availability} loading={totals.loading} />
				</div>

				<div className="col-sm-12 col-md-6 col-lg-3">
					<DashboardCard title="Final Confirmations" value={totals.finalConfs} path="/finalconfirmations" icon={ICONS.finalConfirmation} loading={totals.loading} />
				</div>

				<div className="col-sm-12 col-md-6 col-lg-3">
					<DashboardCard title="Products Requiring Action" value={totals.products} path="/products" icon={ICONS.products} loading={totals.loading} />
				</div>
			</div>

			<div className="row">
				<div className="col-12">

					<div className="row">

						<div className="col-md-12 col-lg-6 col-xl-4">
							<div className="inner-content">
								<h3>Calendar</h3>
								<Calendar view="small" updateSummary={updateSummary} />
							</div>
						</div>

						<div className="col-md-12 col-lg-6 col-xl-8">
							<div className="inner-content">
								<h3>Bookings - {upcomingTitle}</h3>

								{loading ? (
									<div className="spinner-container dash-stats">
										<div className="spinner-border" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>
								) :
									<UpcomingEvents upcomingData={upcomingData} type={summaryType}/>
								}
							</div>
						</div>

					</div>

				</div>
			</div>

			{requireGDPR && <GDPRmodal setRequireGDPR={setRequireGDPR} />}

		</div>
    )
}

const DashboardCard = ({ title, value, path, icon, loading }) => (
	<div className="card dashboard-card">
		{loading ? (
		<div className="spinner-container dash-stats">
			<div className="spinner-border" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
		) : (
		<>
			<div className="flex-container">
				<p>{title}</p>
				<div className="icon-bg">
					<Icon icon={icon} viewBox={icon.viewBox} width={'25'} height={'25'} color={'#ffffff'} />
				</div>
			</div>
			<div className="flex-container bottom">
				<span className="dashboard-value">{value}</span>
				<Link className="navbar-item" to={path}>View <Icon icon={ICONS.chevronDown} viewBox={ICONS.chevronDown.viewBox} width={'20'} height={'20'} color={'#000000'} /></Link>
			</div>
		</>
		)}
    </div>
);

const UpcomingEvents = ({upcomingData, type}) => (

		<div className="row">

			{upcomingData != null && upcomingData.length > 0 ?
			<>
			{upcomingData.map((item) => (
				<div key={item.bookingItemId} className="col-6">
					<div className="upcoming-booking">
					<a href={'requests/booking/' + item.bookingItemType + "/" + item.bookingItemId}>
						<div className="row">
							<div className="col-12">
								<p><Icon icon={ICONS.calendar} viewBox={ICONS.calendar.viewBox} width={'20'} height={'20'} color={'#000000'} />{item.date}</p>
								<p className={`status ${item.status.type}`}>{item.status.label}</p>
								<hr/>
								<strong>{item.ref} - {item.client}</strong>
								<p>{item.location}</p>
								<p>{item.name}</p>
								<p style={{color: '#666'}}>{item.isExpired ? "This booking has already gone" : ""}</p>
							</div>
						</div>
						</a>
					</div>
				</div>
			))}
		</>
		 : (
			<div className="col-12">
				<p>There are currently no bookings this {type == 'daily' ? 'day' : 'month'}</p>
			</div>
		)}

	</div>

);

export default Dashboard;
