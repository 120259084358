import React from 'react';
import TimeGrid from './time-grid';

const EventNormalForm = ({data, setFormData, fieldHasError, doSubmitForm, posted}) => {

	const handleGridClick = (time) => {
		let index = data.timeSlots.findIndex( item => item == time );
		let newSelectedTimes = [...data.timeSlots];

		if(index > -1){
			newSelectedTimes.splice(index, 1);
		} else {
			newSelectedTimes.push(time);
		}

		newSelectedTimes.sort();
		setFormData( {...data, timeSlots: newSelectedTimes });
	}

	//Intercept submit and stop it posting
	const handleSubmit = (event) => {
		event.preventDefault();
		doSubmitForm()
	}

	//Work out which fields to display
	let showTimeSlots = data.action === 'maybe';
	let showReason = data.action === 'maybe' && data.timeSlots.length > 0 || data.action === 'decline';
	let showNameEmail = data.action === 'maybe' && data.timeSlots.length > 0 || data.action === 'decline' || data.action == 'accept';
	const showSubmit = data.action !== '';
	let timePreference = null;

	return (

		<div className="card card p-2 mt-3 p-md-4">

			<h5 className="card-title">Please complete your details below</h5>
			<form name="avail" method="post" onSubmit={handleSubmit}>

				<div className="actions-wrap">
					<p>How would you like to proceed?</p>
					<button type="button" className={data.action == 'accept'? 'btn btn-outline-success mr-4 bg-success text-white' : 'btn btn-outline-success mr-4'} onClick={() => setFormData({...data, action: 'accept'})}>
						Accept
					</button>
					<button type="button" className={data.action == 'maybe'? 'btn btn-outline-warning mr-4 bg-warning text-white' : 'btn btn-outline-warning mr-4'} onClick={() => setFormData({...data, action: 'maybe'})}>
						Flag as conditional
					</button>
					<button type="button" className={data.action=='decline' ? 'btn btn-outline-danger mr-4 bg-danger text-white' : 'btn btn-outline-danger mr-4'} onClick={() => setFormData({...data, action: 'decline'})}>
						Decline
					</button>
				</div>

				{showTimeSlots &&
				<div className="form-section">
					<TimeGrid defaultTimePreference={timePreference} selectedTimes={data.timeSlots} handleClick={handleGridClick}/>
				</div>
				}

				<div className="form-flex-container form-input-wrap">

					{showReason && (
						<div className="form-group form-section">
							{data.action === 'maybe' && <p>If you have any other booking conditions, please enter them below:</p>}
							{data.action === 'decline' && <p>Reason:</p>}
							<textarea className={fieldHasError("reason") ? "form-control is-invalid" : "form-control"} name="reason" value={data.reason} onChange={e => setFormData( {...data, reason: e.target.value })} placeholder={data.action === 'maybe'  ? "This would be anything around minimum numbers etc." : "Fully booked for this date etc."} rows="5"></textarea>
						</div>
					)}

					{showNameEmail && (
						<div className="form-section">
							<div className="form-group">
								<label>Your first name: <small className="text-danger">required</small></label>
								<input className={fieldHasError("firstname") ? "form-control is-invalid" : "form-control"} type="text" name="firstname" value={data.firstname} onChange={e => setFormData( {...data, firstname: e.target.value })} placeholder="Please enter your firstname" />
							</div>

							<div className="form-group">
								<label>Your surname: <small className="text-danger">required</small></label>
								<input className={fieldHasError("surname") ? "form-control is-invalid" : "form-control"} type="text" name="surname" value={data.surname} onChange={e => setFormData( {...data, surname: e.target.value })}  placeholder="Please enter your surname"/>
							</div>

							<div className="form-group">
								<label>Your email: <small className="text-danger">required</small></label>
								<input className={fieldHasError("email") ? "form-control is-invalid" : "form-control"} type="text" name="email" value={data.email} onChange={e => setFormData( {...data, email: e.target.value })}  placeholder="Please enter your email"/>
							</div>
						</div>
					)}

					{showSubmit &&
					<button className="btn btn-lg submit-btn">
						{posted ? (
							<div className="btn-spinner-container">
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						) : "Submit"}
					</button>
					}

				</div>

			</form>

		</div>

	)

}

export default EventNormalForm;
