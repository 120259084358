import React, {useEffect, useState} from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TimeGrid = ({selectedTimes, handleClick, defaultTimePreference}) => {

	let index = 0;
	if( defaultTimePreference === 'afternoon' ){
		index = 1;
	} else if( defaultTimePreference === 'evening' ){
		index = 2;
	}
	const[activeTab, setActiveTab] = useState(index);

	const morning = [
		'07:00', '07:15', '07:30', '07:45',
		'08:00', '08:15', '08:30', '08:45',
		'09:00', '09:15', '09:30', '09:45',
		'10:00', '10:15', '10:30', '10:45',
		'11:00', '11:15', '11:30', '11:45'
	]

	const afternoon = [
		'12:00', '12:15', '12:30', '12:45',
		'13:00', '13:15', '13:30', '13:45',
		'14:00', '14:15', '14:30', '14:45',
		'15:00', '15:15', '15:30', '15:45',
		'16:00', '16:15', '16:30', '16:45',
		'17:00', '17:15', '17:30', '17:45'
	]

	const evening = [
		'18:00', '18:15', '18:30', '18:45',
		'19:00', '19:15', '19:30', '19:45',
		'20:00', '20:15', '20:30', '20:45',
		'21:00', '21:15', '21:30', '21:45',
		'22:00', '22:15', '22:30', '22:45',
		'23:00', '23:15', '23:30', '23:45',
	]

	const isSelected = (time, returnIndex=false) => {
		let index = selectedTimes.findIndex( item => time == item );
		return returnIndex ? index : index >- 1;
	}

	return (
		<div className="row">
			<div className="col-12">

				<div className="time-grid">

					<p>What times are available on this date:</p>

					<Tabs selectedIndex={activeTab} onSelect={i => setActiveTab(i)}>
						<TabList>
							<Tab>Morning</Tab>
							<Tab>Afternoon</Tab>
							<Tab>Evening</Tab>
						</TabList>

						<TabPanel>
							<div className="row">
								<div className="col-12">
									{morning.map( (item, index) =>
										<button key={`morning-slot-${index}`} type="button" onClick={(e)=>handleClick(item)} className={isSelected(item) ? "btn secondary btn-sm col-3" : "btn secondary inverted btn-sm col-3"}>{item}</button>
									)}
								</div>
							</div>
						</TabPanel>

						<TabPanel>
							<div className="row">
								<div className="col-12">
									{afternoon.map( (item, index) =>
										<button key={`afternoon-slot-${index}`} type="button" onClick={(e)=>handleClick(item)} className={isSelected(item) ? "btn secondary btn-sm col-3" : "btn secondary inverted btn-sm col-3"}>{item}</button>
									)}
								</div>
							</div>
						</TabPanel>

						<TabPanel>
							<div className="row">
								<div className="col-12">
									{evening.map( (item, index) =>
										<button key={`evening-slot-${index}`} type="button" onClick={(e)=>handleClick(item)} className={isSelected(item) ? "btn secondary btn-sm col-3" : "btn secondary inverted btn-sm col-3"}>{item}</button>
									)}
								</div>
							</div>
						</TabPanel>

					</Tabs>

					{selectedTimes.length > 0 ? (
						<div className="form-group selected-times">
							<label className="label-control">Available times:</label>
							<div className="row">
							{selectedTimes.map( (time, index) =>
								<div key={`selected-item-${index}`} className="col-3">
									<div className="time-slot">{time}</div>
								</div>
							)}
							</div>
						</div>
					):null}

				</div>

			</div>
		</div>

	)
}

export default TimeGrid;
