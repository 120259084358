import React from "react";
import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'


const HotelCard = ({booking, notes}) => {

	if( notes===undefined ){
		notes = '';
	}

	return (
		<div>

			<div className="details card p-2 p-md-4 mt-3 mb-4">
				<div className="details__header">
					<h5>Client Details</h5>
				</div>

				<div className="flex-card-container">
					<p className="flex-item-2"><Icon icon={ICONS.user} viewBox={ICONS.user.viewBox} width={'16'} height={'16'} color={'#000000'} /><strong>Leader</strong></p>
					<p className="flex-item-2 text-right">{booking.client.name}</p>
				</div>

				{booking.client.phone ? (
					<div className="flex-card-container">
						<p><Icon icon={ICONS.phone} viewBox={ICONS.phone.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Phone</span></p>
						<p className="client-phone text-right flex-item-2"><small>{booking.client.phone}</small></p>
					</div>
				):null}

				{booking.client.email ? (
					<div className="flex-card-container">
						<p><Icon icon={ICONS.mail} viewBox={ICONS.mail.viewBox} width={'30'} height={'20'} color={'#000000'} /><span className="details__item">Email</span></p>
						<p className="client-phone text-right flex-item-2"><small>{booking.client.email}</small></p>
					</div>
				):null}

				{ booking.client.email || booking.client.phone ? (
					<div className="flex-card-container">
						<p>
							Please note, the customer details are being shared, should you need to get hold of 
							the customer over the departure weekend. Please do not contact the customer prior to this
						</p>
					</div>
				):null}

				{booking.hotel.venue ? (
					<div className="flex-card-container">
						<p className="flex-item-2"><Icon icon={ICONS.location} viewBox={ICONS.location.viewBox} width={'16'} height={'16'} color={'#000000'} /><strong>Location</strong></p>
						<p className="flex-item-2 text-right">{booking.hotel.venue.map( (address, index) => <span key={`address-row-${index}`}>{address}<br /></span>)}</p>
					</div>
				):(
					<div className="flex-card-container">
						<p className="flex-item-2"><Icon icon={ICONS.location} viewBox={ICONS.location.viewBox} width={'16'} height={'16'} color={'#000000'} /><strong>Location</strong></p>
						<p className="flex-item-2 text-right">{booking.location}</p>
					</div>
				)}

			</div>

			<div className="activity-details card p-2 p-md-4 mb-4">
				<div className="details__header">
					<h5>{booking.hotel.name}</h5>
					{booking.status !== null ? (
						<div className="status-container">
							<div className="flex-card-container">
								<div className={`flex-item-2 status ${booking.status.type}`}>
									{booking.status.label}
								</div>
								<div className="flex-item-2">
									<span className="status__name">By {booking.status.by}</span>
									<span className="status__date"> {booking.status.date}</span>
								</div>
							</div>
							<div className="status__message">
								<p>{booking.status.description}</p>
							</div>
						</div>
					) : null}
				</div>

				{booking.hotel.displayDetails ? (
				<table className="table table-responsive-sm">
					<thead className="thead-dark">
					<tr>
						<th scope="col">Date</th>
						<th scope="col">Type</th>
						<th scope="col">People</th>
						<th scope="col">Rooms</th>
						<th scope="col">Cost (per room)</th>
					</tr>
					</thead>
					<tbody>
					{booking.hotel.nights.map( (night, index) =>
						<React.Fragment key={`bh-row-${index}`}>
							{night.rooms.map((room) => (
								<tr key={room.id}>
									<td>{night.date}</td>
									<td>{room.type}</td>
									<td>{room.pax}</td>
									<td>{room.rooms}</td>
									<td>{room.cost.currencyPrefix ? (
										<b><span dangerouslySetInnerHTML={{ __html: room.cost.currency}} />{room.cost.amount}</b>
									) : (
										<b>{room.cost.amount}<span dangerouslySetInnerHTML={{ __html: room.cost.currency }} /></b>
									)}</td>
								</tr>
							))}
						</React.Fragment>
					)}
					</tbody>
				</table>
				) : (
					<table className="table table-responsive-sm">
						<thead className="thead-dark">
						<tr>
							<th scope="col">Hotel</th>
							<th scope="col">Date</th>
							<th scope="col">People</th>
						</tr>
						</thead>
						<tbody>
						{booking.hotel.nights.map((night, index) =>
							<tr key={night.id}>
								<td>{booking.hotel.name}</td>
								<td>{night.date}</td>
								<td>{night.people}</td>
							</tr>
						)}
						</tbody>
					</table>
				)}
				{booking.hotel.allocation && booking.hotel.allocation > 0 ? <p>This booking will use {booking.hotel.allocation} rooms from our allocation</p> :null}

				{notes.length > 0 ? (
					<div>
						<div className="details__header mt-3">
							<h5 className="status-title">Notes</h5>
						</div>
						<p className="client-phone" dangerouslySetInnerHTML={{ __html: notes}} />
					</div>
				):null}


			</div>

		</div>
	)
}

export default HotelCard;
