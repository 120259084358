import React, {Component, useState, useEffect, useContext} from "react";
import { store } from "../../context/store";
import {usePost, useFetch} from '../../shared/hooks';
import {handleApiError} from "../../context/api_error";
import {Link} from "@reach/router";
import {ICONS} from '../shared/icons';
import Icon from '../shared/icon-component';
import Alert from '../shared/alert';

const Settings = () => {

	const MIN_LENGTH = 7;
	const SPECIAL_CHARS = [".", "?", "!", "£", "@", "-", "_", "$" ];

	const [globalData, setGlobalData] = useContext( store );
	const {raise, reset} = handleApiError();
	const [formData, setFormData] = useState({ password: {error:false, value:''}, confirm: {error:false, value: ''} });
	const {data, loading} = useFetch("/api/settings/view", { branches:[]} );
	const [post, { posted, error, responseData, errorFields }] = usePost('/api/settings/passwordupdate' );
	const [post2, { posted2, error2, responseData2, errorFields2 }] = usePost('/api/settings/versionupdate' );
	const [showSuccess, setShowSuccess] = useState(null);

	useEffect( () => {
		if( responseData !== null && responseData.status == 200 && responseData.success ){
			setShowSuccess('Your changes have been saved');
		}
	})

	const onSubmit = (e) => {

		e.preventDefault();

		reset();

		let password  = formData.password.value;
		let confirm  = formData.confirm.value;
		let isValid = true;

		//check passwords match
		if( password !== confirm ){
			setFormData({...formData, confirm: {error:true}, password: {error:true} });
			raise('Please make sure your passwords match!');
			isValid = false;
		}

		//check min length
		if( !(typeof password === 'string' && password.length >= MIN_LENGTH) ){
			setFormData({...formData, password: {error:true} });
			raise(`Please ensure your new password is at least ${MIN_LENGTH} characters long!`);
			isValid = false;
		}

		//check one number and/or special character
		let hasNumber = false, hasSpecial = false;
		if(password.match(/\d/)){
			hasNumber = true;
		}
		for(let i=0; i < SPECIAL_CHARS.length; i++ ){
			if( password.includes(SPECIAL_CHARS[i]) ){
				hasSpecial = true;
			}
		}

		if( hasSpecial == false && hasNumber == false ){
			setFormData({...formData, password: {error:true} });
			let specialChars = SPECIAL_CHARS.join(' ');
			raise(`Please ensure your new password has a number, or at least one of the following characters: ${specialChars}`);
			isValid = false;
		}

		if( isValid ){
			let myPostData = new FormData();
			myPostData.append('password', password);
			myPostData.append('confirm', confirm);
			post(myPostData)
			return true;
		}

	}

	return (
		<div className="settings-wrap">
			<h1 className="section-h1">
				<Icon icon={ICONS.settings} viewBox={ICONS.settings.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
				Settings
			</h1>
			<div className="inner-content p-2 p-md-5">

				<Link to="/" className="back-link"><Icon icon={ICONS.chevronLeft} viewBox={ICONS.chevronLeft.viewBox} width={'14'} height={'14'} color={'#2c3343'} /> Back</Link>

				{loading ? (
				<div className="spinner-container">
					<div className="spinner-border" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
				) : (
				<div className="row">
					<div className="col-md-12 col-lg-6">
						{showSuccess!==null ? (
							<Alert type={'success'}/>
						) : (
							<form className="card p-2 p-md-4 mb-3 mt-3" onSubmit={onSubmit} method="post" action="/settings">
								<h5>Change Password</h5>

								<p>Please make sure your new password contains the following</p>
								<ul>
									<li>At least {MIN_LENGTH} characters</li>
									<li>A number, OR one of the following special characters ({SPECIAL_CHARS.map((c)=> `${c} `)})</li>
								</ul>

								<div className="row">
									<div className="form-group col-12">
										<label className="d-block">Your new Password</label>
										<input type="password" className={formData.password.error ? "form-control is-invalid" : "form-control"} name="password" placeholder="Enter your new password" onChange={(e) => setFormData({...formData, password: {value:e.target.value, error:false}})} required />
									</div>
									<div className="form-group col-12">
										<label className="d-block">Confirm your new Password</label>
										<input type="password" className={formData.confirm.error ? "form-control is-invalid" : "form-control" } name="confirm" placeholder="Confirm your new password" onChange={(e) => setFormData({...formData, confirm: {value: e.target.value, error:false }})}required/>
									</div>
								</div>

								<button className="btn submit-btn btn-block" type="submit">
									{posted ? (
										<div className="btn-spinner-container">
											<div className="spinner-border" role="status">
												<span className="sr-only">Loading...</span>
											</div>
										</div>
									) : "Submit"}
								</button>

							</form>
						)}

					</div>


					{data.branches.map( (branch) =>
					<div key={branch.address} className="col-md-12 col-lg-6">
						<div className="details card p-2 p-md-4 mt-3 mb-3">
							<div className="details__header">
								<h5>{branch.name}</h5>
							</div>

							<div className="flex-container">
								<p><Icon icon={ICONS.user} viewBox={ICONS.user.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Branch</span></p>
								<p className="client-phone text-right flex-item-2">{branch.name}</p>
							</div>

							<div className="flex-container">
								<p><Icon icon={ICONS.phone} viewBox={ICONS.phone.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Phone</span></p>
								<p className="client-phone text-right flex-item-2">{branch.phone ? branch.phone : 'None added'}</p>
							</div>

							<div className="flex-container">
								<p className="flex-item-2"><Icon icon={ICONS.location} viewBox={ICONS.location.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Address</span></p>
								<p className="client-address flex-item-2 text-right">
									{branch.address.map( (addressLine) =>
										<span key={addressLine}>{addressLine}</span>
									)}
								</p>
							</div>

						</div>
					</div>
					)}

				</div>
				)}
			</div>

		</div>
	)
}

export default Settings;
