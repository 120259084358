import React, {useState, useContext, useEffect} from "react";
import {Link} from "@reach/router";
import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'
import Alert from '../shared/alert';
import HotelCard from '../shared/hotel-card';

//Import contexts & hooks
import {useFetch, usePost} from '../../shared/hooks';
import {useTotals} from "../../context/totals";
import {handleApiError} from "../../context/api_error";
import {store} from "../../context/store";

//Import forms
import HotelForm from './shared/hotel-form'

const AvailabilityHotel = ({requestId}) => {

	const {totals, dispatch } = useTotals();
	const {raise, reset} = handleApiError();
	const[globalData, setGlobalData] = useContext( store );
	const[requestType, setRequestType] = useState(false);
	const[flash, setFlash] = useState('');
	const[fieldErrors, setFieldErrors] = useState([]);

	const { data, loading } = useFetch(`/api/availability/hotel/${requestId}`, { booking: null, request: null  });
	const [ post, { posted, error, responseData, errorFields }] = usePost('/api/availability/update' );

	//Watch for changes from update request
	useEffect(() => {
		if( responseData !== null && responseData.success ){
			setFieldErrors([]);//reset field errors
			setFlash(responseData.message); //display success message
			setRequestType(false); //hide form
			dispatch({type:"updateCount", key: "availabilityRequests", count: totals.availabilityRequests-1})
		}
		if( error !== null || responseData !== null ){
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		}
	},[responseData, error]);

	useEffect( () => {
		if( data.booking !== null && data.request !== null ){
			setRequestType(data.request.form);
		}
	},[data]);

	const initialFormData = {
		action: '',
		firstname: globalData.firstname || '',
		surname: globalData.surname || '',
		email: globalData.email || '',
		reason: '',
	}
	const[formData, setFormData] = useState(initialFormData);


	const formIsValid = (action=null) => {

		if( action === null ){
			action = formData.action;
		}

		//have to set separate flag as state.errors is async so cant rely on it
		let formErrors = [];

		if( requestType !== 'cancel' && action===null ) {
			formErrors.push('action');
		}

		if( formData.firstname.length < 2 ) {
			formErrors.push("firstname");
		}

		if( formData.surname.length < 2 ) {
			formErrors.push("surname");
		}

		if( formData.email.length < 2 ) {
			formErrors.push("email");
		}

		if( requestType === 'normal' && action !== 'accept' && formData.reason.length < 2 ){
			formErrors.push("reason");
		}

		if( requestType === 'price' && action === 'maybe' && formData.reason.length < 1 ){
			formErrors.push("reason");
		}

		setFieldErrors(formErrors);
		return formErrors.length > 0 ? false : true;

	}

	const fieldHasError = (key) => {
		return !!fieldErrors.find( err => err===key );
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		doSubmitForm();
	}

	const doSubmitForm = () => {
		if( formIsValid() ) {
			const postData = new FormData();
			postData.append('bookingId', data.booking.id);
			postData.append('bookingItemId', data.booking.hotel.id);
			postData.append('type', 'hotel');
			postData.append('action', formData.action );
			postData.append('firstname', formData.firstname);
			postData.append('surname', formData.surname);
			postData.append('email', formData.email);
			postData.append('reason', formData.reason);
			post( postData );
		} else {
			raise("Please check below to make sure you have filled the form out correctly.");
		}
	}

	let notes = null;
	if( data.booking !== null )  {
		notes = data.request.notes;
		if( data.request.responseNotes !== undefined ) {
			notes = notes + '<h4>Your notes</h4>' + data.request.responseNotes;
		}
	}
	
	return (
		<div>

		<div className="availability-event-wrap">

			<h1 className="section-h1">
				<Icon icon={ICONS.availability} viewBox={ICONS.availability.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
				Availability Requests
			</h1>

			<div className="inner-content">

				<Link to="/requests/availability" className="back-link"><Icon icon={ICONS.chevronLeft} viewBox={ICONS.chevronLeft.viewBox} width={'14'} height={'14'} color={'#2c3343'} /> Back</Link>

				{data.booking !== null ? (
					<div>
						<div className="flex-container reference-wrap">
							<h2 className="flex-item-2">Availability request: {data.booking.ref}</h2>
						</div>

						{flash && <Alert title="Congratulations" message={flash} />}

						<div className="row">

							<div className="col-sm-12 col-lg-12 col-xl-6">
								<HotelCard booking={data.booking} notes={notes} />
							</div>

							<div className="col-sm-12 col-lg-12 col-xl-6">

								{requestType && (
									<React.Fragment>
									<div className="card mb-4 p-2 p-md-4">
										<h5 className="card-title">Message from the Eventa Group</h5>
										<div className="text-container">
											{requestType === 'normal' && (
												<React.Fragment>
													<p>We have a customer that has requested to book with you!</p>
													<p>If you currently have availability for this request, please click <strong className="text-success">Accept</strong>. We will aim to send this back through as a new booking request within the next 72 hours - please do not book this onto your system until you receive the new 'Booking request'.</p>
													<p>If you have availability on the day but using a different room configurations, please click the <strong className="text-warning">Flag as conditional</strong> and let us know what you can provide.</p>
													<p>If you do not have availability and are unable to accommodate this booking please <strong className="text-danger">Decline</strong> and let us know what the
														issue is.</p>
												</React.Fragment>
											)}
											{requestType === 'price' && (
												<React.Fragment>
													<p>We have a customer that has requested to book with you!</p>
													<p>If you're able to accommodate this, please select <strong className="text-warning">Flag as conditional</strong> and let us know the room types & costs you have available.</p>
													<p>If you do not have availability on these dates please select <strong className="text-danger">Decline</strong> and we will let the customer know.</p>
												</React.Fragment>
											)}
											<p><strong>Please note</strong> <i>this is an availability request - do not book this onto your system until we have sent through the full booking request - we aim to have this out to you within 48 hours of you responding to this request</i></p>

										</div>

									</div>

									<HotelForm
										itemType="hotel"
										handleSubmit={handleSubmit}
										formType={requestType}
										data={formData}
										setFormData={setFormData}
										fieldHasError={fieldHasError}
										doSubmitForm={doSubmitForm}
										posted={posted}
									/>

									</React.Fragment>
								)}

							</div>

						</div>
					</div>

				) : (
					<div className="spinner-container">
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				)}

			</div>

		</div>

	</div>
	)
}

export default AvailabilityHotel;
