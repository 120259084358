import React, {useState} from 'react';

const FlightDetails = ({details}) => {

	const[show, setShow] = useState(false);

	return (

		<div className="details card p-4 mt-3 mb-3">

			<div className="details__header">
				<h5>Flight and accommodation Details</h5>
			</div>

			{details !== null ? (
			<React.Fragment>
				<button type="button" className="btn btn-outline-success btn-xs pull-right" onClick={()=>setShow(!show)}>{show ? 'Hide' : 'View'}</button>

				{show ? (
					<React.Fragment>
						<table className="table table-bordered table-striped table-condensed">
							<thead>
							<tr>
								<th colSpan="2">Outbound details</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td>Departure date</td>
								<td>{details.outbound_departure_date}</td>
							</tr>
							<tr>
								<td>Departure time</td>
								<td>{details.outbound_departure_time}</td>
							</tr>
							<tr>
								<td>Arrival time (24 hour clock)</td>
								<td>{details.outbound_arrival_time}</td>
							</tr>
							<tr>
								<td>Airline</td>
								<td>{details.outbound_airline}</td>
							</tr>
							<tr>
								<td>Flight number</td>
								<td>{details.outbound_flight_number}</td>
							</tr>
							<tr>
								<td>Departure airport</td>
								<td>{details.outbound_departure_airport}</td>
							</tr>
							<tr>
								<td>Arrival airport</td>
								<td>{details.outbound_arrival_airport}</td>
							</tr>
							</tbody>
						</table>

						<br/>

						<table className="table table-bordered table-striped table-condensed">
							<thead>
							<tr>
								<th colSpan="2">Return details</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td>Departure date</td>
								<td>{details.return_departure_date}</td>
							</tr>
							<tr>
								<td>Departure time</td>
								<td>{details.return_departure_time}</td>
							</tr>
							<tr>
								<td>Arrival time (24 hour clock)</td>
								<td>{details.return_arrival_time}</td>
							</tr>
							<tr>
								<td>Airline</td>
								<td>{details.return_airline}</td>
							</tr>
							<tr>
								<td>Flight number</td>
								<td>{details.return_flight_number}</td>
							</tr>
							<tr>
								<td>Departure airport</td>
								<td>{details.return_departure_airport}</td>
							</tr>
							<tr>
								<td>Arrival airport</td>
								<td>{details.return_arrival_airport}</td>
							</tr>
							</tbody>
						</table>

						<br/>

						<table className="table table-bordered table-striped table-condensed">
							<thead>
							<tr>
								<th colSpan="2">Additional details</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td>Accommodation name</td>
								<td>{details.accommodation_name}</td>
							</tr>
							<tr>
								<td>Accommodation address</td>
								<td>{details.accommodation_address ? details.accommodation_address : "Not given"}</td>
							</tr>
							<tr>
								<td>Contact number</td>
								<td>{details.contact_phone}</td>
							</tr>
							</tbody>
						</table>
					</React.Fragment>
				) : null}

			</React.Fragment>
		) : (
			<i className="null">As soon as these become available you will be notified by email.</i>
		)}

		</div>
	)

}
export default FlightDetails;