import React, {useState, useContext, useEffect} from "react";
import {Link} from "@reach/router";
import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'
import Alert from '../shared/alert'
import ActivityCard from '../../components/shared/actvity-card';

//Import contexts & hooks
import {useFetch, usePost} from '../../shared/hooks';
import {useTotals} from "../../context/totals";
import {handleApiError} from "../../context/api_error";
import {store} from "../../context/store";

//Import forms
import EventTimeForm from './shared/event-time-form'
import EventNormalForm from './shared/event-normal-form'

const AvailabilityEvent = ({requestId}) => {

	const {totals, dispatch } = useTotals();
	const {raise, reset} = handleApiError();
	const[globalData, setGlobalData] = useContext( store );
	const[requestType, setRequestType] = useState(false);
	const[flash, setFlash] = useState('');
	const[fieldErrors, setFieldErrors] = useState([]);

	const{ data, loading} = useFetch(`/api/availability/event/${requestId}`, {booking:null, request:null} );
	const[ post, { posted, error, responseData, errorFields }] = usePost('/api/availability/update' );

	useEffect( () => {
		if( data.booking !== null && data.form !== null ){
			setRequestType(data.request.form);
		}
	},[data]);

	//Watch for changes from update request
	useEffect(() => {
		if( responseData!==null && responseData.success ){
			setFieldErrors([]);//reset field errors
			setFlash(responseData.message); //display success message
			setRequestType(false); //hide form
			dispatch({type:"updateCount", key: "availabilityRequests", count: totals.availabilityRequests-1})
		}
		if( error !== null || responseData !== null ){
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		}

		if( errorFields!==null ){
			setFieldErrors([...fieldErrors, errorFields]);
		}

	},[responseData, error, errorFields]);

	const initialFormData = {
		action: '',
		firstname: globalData.firstname || '',
		surname: globalData.surname || '',
		email: globalData.email || '',
		reason: '',
		timeSlots: [],
		price: '',
	};
	const[formData, setFormData] = useState(initialFormData);

	const formIsValid = (action=null) => {

		if( action === null ){
			action = formData.action;
		}

		//have to set separate flag as state.errors is async so cant rely on it
		let formErrors = [];

		if( requestType !== 'cancel' && action===null ) {
			formErrors.push('action');
		}

		if( formData.firstname.length < 2 ) {
			formErrors.push("firstname");
		}

		if( formData.surname.length < 2 ) {
			formErrors.push("surname");
		}

		if( formData.email.length < 2 ) {
			formErrors.push("email");
		}

		if( requestType === 'time' && action === 'maybe' && formData.timeSlots.length == 0 ){
			formErrors.push("times");
		}

		if( requestType === 'time' && action === 'decline' && formData.reason.length < 2 ){
			formErrors.push("reason");
		} else if( requestType === 'normal' && action !== 'accept' && formData.reason.length < 2 ){
			formErrors.push("reason");
		}

		setFieldErrors(formErrors);
		return formErrors.length > 0 ? false : true;

	}

	const fieldHasError = (key) => {
		return !!fieldErrors.find( err => err===key );
	}

	const doSubmitForm = (type=null) => {
		if( formIsValid(type) ){
			const postData = new FormData();
			postData.append('bookingId', data.booking.id );
			postData.append('bookingItemId', data.booking.event.id );
			postData.append('type', 'event' );
			postData.append('action', type !== null ? type : formData.action );
			postData.append('firstname', formData.firstname );
			postData.append('surname', formData.surname );
			postData.append('email', formData.email );
			postData.append('reason', formData.reason );
			postData.append('price', formData.price );
			if( requestType === 'time' && formData.timeSlots !== null && formData.timeSlots.length > 0 ){
				formData.timeSlots.map( (item) => {
					postData.append('timeSlots[]', item);
				})
			}

			post( postData );

		} else {
			raise('Something seems to have gone wrong! Please make sure you have filled in the form fully.');
		}
	}

	const getMessage = (type) => {
		switch(type){
			case 'time':
				return (
					<div className="card mb-4 mt-3 p-2 p-md-4">
						<h5 className="card-title">Notes from the Eventa Group</h5>
						<div className="text-container">
							<p>We have a customer that has requested to book with you. {data.booking.event.timePreference ? `Preferably, they'd like this to take place in the ${data.booking.event.timePreference}.` : ""}</p>
							<p>If you have availability for this date please select <strong className="text-warning">Flag as conditional</strong> and select all the times you have available. We will then confirm the chosen time with a new booking request.</p>
							<p>If you do not have availability please <strong className="text-danger">Decline</strong> and let us know what the issue is.</p>
						</div>

						<p><strong>Please note</strong> <i>this is an availability request - do not book this onto your system until we have sent through the full booking request - we aim to have this out to you within 48 hours of you responding to this request</i></p>
					</div>
				)
			case 'cancel':
				return (
					<div className="card mb-4">
						<div className="card-header bg-danger text-white">
							<h4 className="card-title">
								<i className="glyphicon glyphicon-warning-sign"/> Cancellation request
							</h4>
						</div>
						<div className="card-body">
							<p>Unfortunately, this activity is no longer going ahead.</p>
						</div>
					</div>
				)
			case 'normal':
				return (
					<div className="card mb-4 mt-3 p-2 p-md-4">
						<h5 className="card-title">Notes from the Eventa Group</h5>
						<div className="text-container">
							<p>If you currently have availability for this request, please click <strong className="text-success">Accept</strong>. We will aim to send this back through as a new booking request within the next 72 hours - please do not book this onto your system until you receive the new 'Booking request'.</p>
							<p>If you have availability on the day but at a different time please click the <strong className="text-warning">Flag as conditional</strong> and pick the nearest time available for this request.</p>
							<p>If you have no availability for this date, please <strong className="text-danger">Decline</strong> and let us know what the issue is.</p>
						</div>
					</div>
				)

		}
	}

	return (
		<div>

			<div className="availability-event-wrap">
				<h1 className="section-h1">
					<Icon icon={ICONS.availability} viewBox={ICONS.availability.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
					Availability Requests
				</h1>

				<div className="inner-content">

					<Link to="/requests/availability" className="back-link"><Icon icon={ICONS.chevronLeft} viewBox={ICONS.chevronLeft.viewBox} width={'14'} height={'14'} color={'#2c3343'} /> Back</Link>

					{data.booking !== null ? (
						<div>

							<div className="flex-container reference-wrap">
								<h2 className="flex-item-2">Availability request: {data.booking.ref}</h2>
							</div>

							{flash ? (
								<Alert title="Congratulations" message={flash} />
							) :null}

							<div className="row">

								<div className="col-sm-12 col-lg-12 col-xl-6">
									<ActivityCard booking={data.booking} notes={data.request.notes}/>
								</div>

								<div className="col-sm-12 col-lg-12 col-xl-6">
									{loading ? (
										<p>Loading..</p>
									) : (
										<div>

											{requestType && getMessage(requestType)}

											{requestType === 'time' ? (
												<EventTimeForm
													data={formData}
													setFormData={setFormData}
													fieldHasError={fieldHasError}
													doSubmitForm={doSubmitForm}
													defaultTimePreference={requestType === 'time' ? data.booking.event.timePreference : false}
													posted={posted}
												/>
											):null}

											{requestType === 'normal' ? (
												<EventNormalForm
													itemType="event"
													data={formData}
													setFormData={setFormData}
													fieldHasError={fieldHasError}
													doSubmitForm={doSubmitForm}
													posted={posted}
												/>
											):null}


										</div>
									)}
								</div>

							</div>
						</div>

					) : (
						<div className="spinner-container">
							<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					)}

				</div>

			</div>

		</div>
	)
}

export default AvailabilityEvent;
