import React, {useState} from "react";
import {useFetch} from "../../shared/hooks";
import {Link, navigate, redirectTo} from "@reach/router";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { ICONS } from "../shared/icons"
import Icon from "../shared/icon-component"

import DataTable from "../shared/datatable"

const Availability = (props) => {

	const initialData = {
		bookings: {
			pending: [],
			completed: []
		}
	};

	const { data, loading } = useFetch("/api/availability/viewall", initialData );

	const options = {
		filterType: 'dropdown',
		selectableRows: 'none',
		responsive: 'stacked',
		viewColumns: false,
		rowsPerPageOptions:	[5,10,15,20],
		textLabels: {
			body: {
			  noMatch: "Congratulations, you have no availability requests to action",
			  columnHeaderTooltip: column => `Sort for ${column.label}`
			},
			pagination: {
			  rowsPerPage: "Rows:",
			  displayRows: "out of",
			}
		},
		onRowClick: (rowData) => {
			navigate(`/requests/availability/${rowData[7]}/${rowData[9]}`);
		},
		setRowProps: (row) => {
		if( row[2].props.className === "pending status" ) {
		  return {
		    className: "pending-row"
		  }
		}
		},
	};

	let options2 = options;
	options2.textLabels.body.noMatch = "Congratulations, all availability requests have been fully processed";

	const columns = [
	  { label: 'ID',      name: "id", options: { filter: false, sort: true, display: false }},
	  { label: 'Ref',     name: "ref", options: { filter: false, sort: true,
	    customBodyRender: (value, tableMeta, updateValue) => (
	      <span className="booking-ref"> {value}</span>
	    )
	  }},
		{ label: 'Status',  name: "statusType", options: { filter: true, sort: true,
	    customBodyRender: (value, tableMeta, updateValue) => (
	      <span className={value.toLowerCase() + ' status'}>{tableMeta.rowData[3]}</span>
	    )
	  }},
	  { label: 'StatusText',  name: "statusText", options: { filter: true, sort: true, display: false}},
	  { label: 'Date',    name: "date", options: { filter: false, sort: true }},
	  { label: 'Client',  name: "client", options: { filter: false, sort: true,
			customBodyRender: (value, tableMeta, updateValue) => (
				<span className="client-name">{value}</span>
			)
		 }},
	  { label: 'Product', name: "product", options: { filter: false, sort: true,
			customBodyRender: (value, tableMeta, updateValue) => (
				<span className="product-name">{value}</span>
			)
		 }},
	  { label: 'Type',    name: "type", options: { filter: true, sort: true, display: false  }},
	  { label: 'Location',name: "location", options: { filter: true, sort: true }},
	  { label: 'Request', name: "bookingItemId", options: { filter: false, sort: true, display: false  }}
	];

	return (
		<div>

			<div className="booking-wrap">
				<h1 className="section-h1">
					<Icon icon={ICONS.availability} viewBox={ICONS.availability.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
					Availability Requests
				</h1>

				<div className="inner-content">
					{loading ? (
						<div className="spinner-container">
							<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					) : (
						<div>
							<Tabs>
								<TabList>
									<Tab>Pending {data.bookings.pending.length > 0 && <span className="badge">{data.bookings.pending.length}</span>}</Tab>
									<Tab>Completed {data.bookings.completed.length > 0 && <span className="badge">{data.bookings.completed.length}</span>}</Tab>
								</TabList>
								<TabPanel>
									<DataTable title={"Pending availability requests"} data={data.bookings.pending} options={options} columns={columns} />
								</TabPanel>
								<TabPanel>
									<DataTable title={"Confirmed availability requests"} data={data.bookings.completed} options={options2} columns={columns} />
								</TabPanel>
							</Tabs>

						</div>
					)}
				</div>

			</div>

		</div>

	)
};

export default Availability;
