import React, { Component, useContext } from "react";
import { Link } from "@reach/router"
import { store } from "../../../context/store";
import Icon from '../../shared/icon-component'
import { ICONS } from '../../../components/shared/icons'

import { navStructure } from "./nav_structure"

const DesktopNavigation = () => {
  
	const [ state, setState ] = useContext( store )

	const isActive = ({ isCurrent }) => {
		return isCurrent ? { className: "active navbar-item" } : {className: 'navbar-item'}
	}

	return (
		<div id="navbar" className="supplier-navbar">
			<div className="nav-supplier-info">
			<div className="avatar">{state.initials}</div>
				<div className="supplier-name">{state.name}</div>
				<div className="supplier-email">{state.email}</div>
			</div>
			<ul>
				{navStructure().map((link, index) => (
					<li key={`nav-desktop-item-${index}`}>
						{link.path == 'no-link' ? (
							<span className="navbar-item"><strong>{link.title}</strong></span>
						) : (
							<Link getProps={isActive} className="navbar-item" to={link.path}><Icon icon={link.icon} viewBox={link.viewBox} width={'16'} height={'16'} color={'#939AA6'} />{link.title} { link.count != undefined && link.count > 0 ? ( <span className="badge">{link.count}</span> ) : null }</Link>
						)}
				</li>
				))}

				<li>
					<a href="/assets/docs/login-guide.pdf" className="navbar-item"><Icon icon={ICONS.question} viewBox={ICONS.question.viewBox} width={'16'} height={'16'} color={'#939AA6'} /> New features</a>
				</li>

			</ul>
		</div>
	
	)
}

export default DesktopNavigation;
