import React, { Component, useContext } from "react";
import {useFetch} from "../../shared/hooks";

import { Link } from "@reach/router"
import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'

import Messaging from '../shared/messaging'

import { store } from "../../context/store";

const BookingsView = ({bookingId}) => {

	const [ state, setState ] = useContext( store )

	const { data, loading } = useFetch(`/api/bookings/view/${bookingId}`, { booking: null, messages: null } );

	return (

			<div className="booking-wrap">

				<h1 className="section-h1">
					<Icon icon={ICONS.bookingRequest} viewBox={ICONS.bookingRequest.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
					{loading ? "Loading...." : "Booking View"}
				</h1>


				<div className="inner-content">

					<Link to="/bookings" className="back-link"><Icon icon={ICONS.chevronLeft} viewBox={ICONS.chevronLeft.viewBox} width={'14'} height={'14'} color={'#2c3343'} /> Back</Link>

					{data.booking === null ? (

						<div className="flex-container reference-wrap">
							<h2 className="flex-item-2">Loading...</h2>
						</div>

					) : (

						<div>

							<div className="flex-container reference-wrap">
								<h2 className="flex-item-2">REF:{data.booking.ref}</h2>
							</div>

							<div className="container-fluid booking-wrap">

								<div className="row">
									<div className="col-md-3">

										<div className="details card p-4 mt-3 mb-3">

											<div className="details__header">
												<h5>Client Details</h5>
											</div>

											<div className="flex-container">
												<p><Icon icon={ICONS.user} viewBox={ICONS.user.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Client</span></p>
												<p className="client-name flex-item-2 text-right">{data.booking.client.name}</p>
											</div>
											{data.booking.client.phone ?
												<div className="flex-container">
													<p><Icon icon={ICONS.user} viewBox={ICONS.user.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Phone</span></p>
													<p className="client-phone text-right flex-item-2">{data.booking.client.phone}</p>
												</div>
											: null }
										</div>

										{data.booking.items.map( item => {

											return (
												<div key={item.id} className="details card p-4">

													<div className="details__header">
														<h5 className="status-title">{item.name}</h5>
														<div className="flex-container">
														<div className="flex-item-2 confirmed status">Confirmed</div>
														<div className="flex-item-2">
															<span>By Alex Marshall</span>
															<span>28/02/2020 - 15:32</span>
														</div>
														</div>
													</div>

													{item.pax ?
														<div className="flex-container">
														<p className="flex-item-2"><Icon icon={ICONS.user} viewBox={ICONS.user.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">People</span></p>
														<p className="client-phone flex-item-2 text-right">{item.pax} people</p>
														</div>
													: null }

													{item.time ?
														<div className="flex-container">
														<p className="flex-item-2"><Icon icon={ICONS.time} viewBox={ICONS.time.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Time</span></p>
														<p className="client-phone flex-item-2 text-right">{item.time}</p>
														</div>
													: null }

													{item.cost ?
														<div className="flex-container">
														<p className="flex-item-2"><Icon icon={ICONS.user} viewBox={ICONS.user.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Price pp</span></p>
														<p className="client-phone flex-item-2 text-right">{item.cost.pp}</p>
														</div>
													: null }

													{item.date ?
														<div className="flex-container">
														<p className="flex-item-2"><Icon icon={ICONS.calendar} viewBox={ICONS.calendar.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Date</span></p>
														<p className="client-phone flex-item-2 text-right">{item.date}</p>
														</div>
													: null }
													{data.booking.location ?
														<div className="flex-container">
														<p className="flex-item-2"><Icon icon={ICONS.location} viewBox={ICONS.location.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Location</span></p>
														<p className="client-phone flex-item-2 text-right">{data.booking.location}</p>
														</div>
													: null }
												</div>
											)
										})}

									</div>

									<div className="col-md-9">
										<Messaging />
									</div>

								</div>

							</div>

						</div>
					)}

					</div>
				</div>
	)

}

export default BookingsView;
