import React, {Component} from "react";

import { ICONS } from '../../shared/icons'
import Icon from '../../shared/icon-component'
import ReactTooltip from "react-tooltip";

const AdditionalInfoCard = ({dataset}) => {

	return (
		<div>

			<div className="details card p-4 mt-3 mb-3">
				<div className="details__header">
					<h4 className="details__header--title">Additional Information <span className="info-icon" data-tip data-event='click focus'><Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'25'} height={'25'} color={'#000000'} /></span></h4>
					<ReactTooltip place="top" type="light" globalEventOff='click' effect="solid" aria-haspopup='true'>
						<h5>Additional Information</h5>
						<p>This information is what our sales team use to help sell your products</p>
					</ReactTooltip>
				</div>

				<div className="product-details">
					<div className="row">
						{dataset.map( (field) =>
							<div className="column col-sm-12 col-md-3" key={field.key}>
								<div className="card p-3">
									<h5>{field.label}</h5>
									{field.isArray ? (
										<ul>
											{field.options.map( (option, index) =>
												<li key={`field-list-item-${index}`}>
													{option.selected ?
														<Icon icon={ICONS.tick} viewBox={ICONS.tick.viewBox} width={'35'} height={'35'} color={'#85b36d'} />
													:
														<Icon icon={ICONS.cross} viewBox={ICONS.cross.viewBox} width={'35'} height={'35'} color={'#ed726f'} />
													}
													{option.label}
												</li>
											)}
										</ul>
									) : (
										<p>{field.value||'None added'}</p>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdditionalInfoCard
