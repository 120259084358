import React, {useState, useEffect, useContext} from 'react';
import {usePost} from '../../../shared/hooks';
import Alert from '../../shared/alert';
import ReactTooltip from "react-tooltip";

import { ICONS } from '../../shared/icons';
import Icon from '../../shared/icon-component';
import {store} from "../../../context/store";

const DatasetForm = ({dataset, eventId, showOptions}) => {

	const[globalData, setGlobalData] = useContext( store );
	const [fields, setFields] = useState(dataset);

	const [success, setSuccess] = useState(false);
	const [formData, setFormData] = useState({
		firstname: globalData.firstname || '',
		surname: globalData.surname || '',
		email: globalData.email || '',
		notes: '',
		action: 'accept'
	});

	const [ post, { posted, error, responseData, errorFields }, resetPost ] = usePost('/api/event/updatedataset' );

	const handleChange = (field, value, checked=false, isAdditional=false) => {

		let index = dataset.findIndex( (f) => f.id === field.id );

		//copy selected field
		let selectedField = { ...dataset[index] };

		if( isAdditional ){
			selectedField.additional.value = value;
		} else {
			if( field.type == 'choices' ){
				//need to figure out which option they toggled
				let optionIndex = selectedField.options.findIndex( (i) => i.value == value );

				let newOptions = [...selectedField.options];
				newOptions[optionIndex].selected = checked;

				let newValues = newOptions.map( (option) => option.selected ? option.value : null )
										  .filter( op => op!==null )
										  .join("|");
				selectedField.options = newOptions;
				selectedField.value = newValues;

			} else {
				//Update value
				selectedField.value = value;
			}
		}

		//Replace selected fields
		dataset.splice(index, 1, selectedField);

		//Copy to new array
		let newFields = [...dataset];

		setFields( newFields );

	};

	const handleSubmit = (e) => {
		e.preventDefault();

		var postData = new FormData();
		postData.append('eventId', eventId);
		postData.append('formType', "dataset");
		postData.append('firstname', formData.firstname);
		postData.append('surname', formData.surname);
		postData.append('email', formData.email);
		postData.append('action', formData.action);
		postData.append('notes', formData.notes);

		dataset.map( (field) => {
			postData.append(field.key,field.value);
			if( field.additional!==null ){
				postData.append(field.additional.key, field.additional.value || '');
			}
		});

		post(postData);

	};

	useEffect( () => {
		if( responseData!==null && responseData.success ){
			setSuccess("Your changes have been saved");
		}
	},[responseData]);

	const fieldHasError = (key) => {
		return errorFields!==null && key===errorFields
	};

	const drawTextInput = (field) => {
		return (
			<div className="form-group">
				<label>{field.label} {field.tooltip ? <span className="info-icon" data-tip data-event='click focus' data-for={`ds-field-${field.id}`}><Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'20'} height={'20'} color={'#000000'} /></span> : null}</label>
				{field.tooltip ? (
					<ReactTooltip id={`ds-field-${field.id}`} place="top" type="light" globalEventOff='click' effect="solid" aria-haspopup="true">
						<h5>{field.label}</h5>
						<p>{field.tooltip}</p>
					</ReactTooltip>
				) : null}
				{field.required ? <span className="required">Required</span> :null}
				<input type="text" value={field.value} className={fieldHasError(field.key) ? "form-control is-invalid" : "form-control"} onChange={(e) => handleChange(field, e.target.value)}/>
			</div>
		)
	}

	const drawTextArea = (field) => {
		return (
			<div className="form-group">
				<label>{field.label} {field.tooltip ? <span className="info-icon" data-tip data-event='click focus' data-for={`ds-field-${field.id}`}><Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'20'} height={'20'} color={'#000000'} /></span> : null}</label>
				{field.required ? <span className="required">Required</span> :null}
				{field.tooltip ? (
					<ReactTooltip id={`ds-field-${field.id}`} place="top" type="light" globalEventOff='click' effect="solid" aria-haspopup='true'>
						<h5>{field.label}</h5>
						<p>{field.tooltip}</p>
					</ReactTooltip>
				) : null}
				<textarea value={field.value} className={fieldHasError(field.key) ? "form-control is-invalid" : "form-control"} onChange={(e) => handleChange(field, e.target.value)}></textarea>
			</div>
		)
	}

	const drawListInput = (field) => {
		return (
			<div>
				<div className="form-group">
					<label>{field.label} {field.tooltip ? <span className="info-icon" data-tip data-event='click focus' data-for={`ds-field-${field.id}`}><Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'20'} height={'20'} color={'#000000'} /></span> : null}</label>
					{field.required ? <span className="required">Required</span> :null}
					{field.tooltip ? (
						<ReactTooltip id={`ds-field-${field.id}`} place="top" type="light" globalEventOff='click' effect="solid" aria-haspopup='true'>
							<h5>{field.label}</h5>
							<p>{field.tooltip}</p>
						</ReactTooltip>
					) : null}
					<select className={fieldHasError(field.key) ? "form-control is-invalid" : "form-control"} defaultValue={field.value} onChange={(e)=>handleChange(field, e.target.value)}>
						<option value={null}>Please select an option</option>
						{field.options.map( (option, index) => <option key={`option-${field.id}-${index}`} value={option.value}>{option.label}</option> )}
					</select>
				</div>
				{field.additional !== null && field.value !== null && field.value.includes(field.additional.trigger) ? (
					<div className="form-group">
						<label>Please provide some additonal details regarding your {field.label}</label>
						<input className={fieldHasError(field.additional.key) ? "form-control is-invalid" : "form-control"} type="text" value={field.additional.value||''} onChange={(e => handleChange(field, e.target.value, false, true))} />
					</div>
				) : null}
			</div>
		)
	}

	const drawCheckBoxes = (field) => {
		return (
			<div>
				<div className="form-group">
					<label>{field.label} {field.tooltip ? <span className="info-icon" data-tip data-event='click focus' data-for={`ds-field-${field.id}`}><Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'20'} height={'20'} color={'#000000'} /></span> : null}</label>
					{field.required ? <span className="required">Required</span> :null}
					{field.tooltip ? (
						<ReactTooltip id={`ds-field-${field.id}`} place="top" type="light" globalEventOff='click' effect="solid" aria-haspopup='true'>
							<h5>{field.label}</h5>
							<p>{field.tooltip}</p>
						</ReactTooltip>
					) : null}
					{field.options.map( (option, index) =>
						<div className="form-check" key={`field-option-${field.id}-${index}`}>
							<label className="form-check-label">
								<input className="form-check-input" type="checkbox" value={option.value||''} defaultChecked={option.selected} onChange={(e)=>handleChange(field, e.target.value, e.target.checked)}/>
								{option.label}
							</label>
						</div>
					)}
				</div>
				{field.additional !== null && field.value !== null && field.value.includes(field.additional.trigger) ? (
					<div className="form-group">
						<label>Please provide some additonal details regarding your {field.label}</label>
						<input className={fieldHasError(field.additional.key) ? "form-control is-invalid" : "form-control"} type="text" value={field.additional.value||''} onChange={(e => handleChange(field, e.target.value, false, true))} />
					</div>
				) : null}
			</div>
		)

	}

	return (
		<div>
			<div className="details card">

				<div className="dataset__header">
					<h4 className="dataset__header--title">Review This Product</h4>
				</div>

				{success ? (
					<Alert type='success' message={success}/>
				) : (
					<form onSubmit={handleSubmit}>
						<div className="persistent-form">

							<div className="row">
								<div className="col-sm-12 col-md-6 col-lg-4">
									<div className="form-group">
										<label>Your firstname</label>
										<span className="required">Required</span>
										<input type="text" value={formData.firstname} className={fieldHasError('firstname') ? "form-control is-invalid" : "form-control"} onChange={(e) => setFormData({...formData, firstname: e.target.value}) }/>
									</div>
								</div>
								<div className="col-sm-12 col-md-6 col-lg-4">
									<div className="form-group">
										<label>Your surname</label>
										<span className="required">Required</span>
										<input type="text" value={formData.surname} className={fieldHasError('surname') ? "form-control is-invalid" : "form-control"} onChange={(e) => setFormData({...formData, surname: e.target.value}) }/>
									</div>
								</div>
								<div className="col-sm-12 col-md-6 col-lg-4">
									<div className="form-group">
										<label>Your email</label>
										<span className="required">Required</span>
										<input type="text" value={formData.email} className={fieldHasError('email') ? "form-control is-invalid" : "form-control"} onChange={(e) => setFormData({...formData, email: e.target.value}) }/>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									{showOptions ? (
										<div>
											<button className="btn btn-outline-success" type="button" onClick={()=>setFormData({...formData, action: 'accept'})}>Accept the details above</button>
											<button className="btn btn-amend" type="button" onClick={()=>setFormData({...formData, action: 'amend'})}>Amend details</button>
										</div>
									) : null}
								</div>
							</div>

						</div>

							{!showOptions || formData.action === 'amend' ? (
								
								<div className="amend-section">
									<h4><Icon icon={ICONS.pencil} viewBox={ICONS.pencil.viewBox} width={'25'} height={'25'} color={'#212529'} /> Amend Additional Information</h4>
									<div className="row">
										{fields.map( (field, index) =>
											<div className="col-12 col-md-6 col-lg-4 col-xl-3" key={`field-row-${index}`}>
												{(() => {
													switch(field.type){
														case 'list':
															return drawListInput(field);
														case 'choices':
															return drawCheckBoxes(field);
														case 'text':
															return drawTextInput(field);
														case 'textarea':
															return drawTextArea(field);
														default:
															return drawTextInput(field);
													}
												})()}
											</div>

										)}
									</div>
									<br />
									<h4><Icon icon={ICONS.pencil} viewBox={ICONS.pencil.viewBox} width={'25'} height={'25'} color={'#212529'} /> Please provide any further information</h4>
									<div className="row">
										<div className="col">
											<div className="form-group">
												<textarea className={`form-control ${fieldHasError("notes") ? "is-invalid" : ""}`} onChange={e => setFormData({...formData, notes: e.target.value})} value={formData.notes} name="notes" placeholder="Please explain any other changes here" rows={5}></textarea>
											</div>
										</div>
									</div>
								</div>

							) : null}
	
							<div className="button-section">
								<button className="btn btn-lg submit-btn" type="submit">
									{posted ? (
										<div className="btn-spinner-container">
											<div className="spinner-border" role="status">
												<span className="sr-only">Loading...</span>
											</div>
										</div>
									) : (
										'Submit'
									)}
								</button>
							</div>

					</form>

				)}
			</div>
		</div>
	)
}
export default DatasetForm;
