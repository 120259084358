import React, {useState, useEffect} from 'react';
import {usePost} from '../../../shared/hooks';
import Alert from '../../shared/alert';
import { handleApiError } from "../../../context/api_error";

import { ICONS } from '../../shared/icons'
import Icon from '../../shared/icon-component'

const NormalForm = ({itemType, itemId}) => {

	const {apiError, message, raise, reset} = handleApiError();
	const [showForm, setShowForm] = useState(true);
	const [success, setSuccess] = useState(false);
	const [formData, setFormData] = useState({firstname: '', surname: '', email: '', action: null, notes: ''});
	const [formErrors, setFormErrors] = useState([]);
	const [post, { posted, error, responseData, errorFields }, resetPost ] = usePost('/api/products/updateproduct' );

	const handleSubmit = (e) => {

		e.preventDefault();

		if( validate() ){
			let postData = new FormData();
			postData.append("itemType", itemType );
			postData.append("itemId", itemId );
			postData.append("firstname", formData.firstname );
			postData.append("surname", formData.surname );
			postData.append("email", formData.email );
			postData.append("notes", formData.notes );
			postData.append("action", formData.action );
			post( postData );
		}

	}

	useEffect( () => {
		if( responseData !== null && responseData.success ){
			setSuccess(responseData.success);
			setShowForm(false);
		}
	},[responseData]);

	const validate = () => {

		let errors = [];

		if( !(formData.firstname.length > 0) ){
			errors.push('firstname');
		}

		if( !(formData.surname.length > 0) ){
			errors.push('surname');
		}

		if( !(formData.email.length > 0) ){
			errors.push('email');
		}

		if( formData.action=='amend' && formData.notes.length < 1 ){
			errors.push('notes');
		}

		setFormErrors(errors);

		if( formData.action===null ){
			raise("Please make sure you select a valid action");
			return false;
		} else {
			return errors.length==0;
		}

	}

	const fieldHasError = (key) => {
		if( errorFields!==null && key==errorFields ){
			return true;
		} else {
			return !!(formErrors.find( i => i==key ));
		}
	}

	return (
		<div>
			{success ? (<Alert type='success' message={success}/>) : null}

			{showForm ? (
				<div className="details card">

					<div className="dataset__header">
						<h4 className="dataset__header--title">Review This Product</h4>
					</div>

					<div className="persistent-form">
						<form onSubmit={handleSubmit} name="important-info" method="post">

							<div className="row">

								<div className="col-sm-12 col-md-6 col-lg-4">
									<div className="form-group">
										<label>Your first name</label>
										<input className={fieldHasError("firstname") ? "form-control is-invalid" : "form-control"} onChange={e => setFormData({...formData, firstname: e.target.value})} type="text" name="firstname" placeholder="Please enter your firstname" />
									</div>
								</div>

								<div className="col-sm-12 col-md-6 col-lg-4">
									<div className="form-group">
										<label>Your surname</label>
										<input className={fieldHasError("surname") ? "form-control is-invalid" : "form-control"} onChange={e => setFormData({...formData, surname: e.target.value})} type="text" name="surname" placeholder="Please enter your surname"/>
									</div>
								</div>

								<div className="col-sm-12 col-md-6 col-lg-4">
									<div className="form-group">
										<label>Your email</label>
										<input className={fieldHasError("email") ? "form-control is-invalid" : "form-control"} onChange={e => setFormData({...formData, email: e.target.value})} type="text" name="email" placeholder="Please enter your email"/>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<button className="btn btn-outline-success" type="button" onClick={()=>setFormData({...formData, action: 'accept'})}>Accept the details above</button>
									<button className="btn btn-amend" type="button" onClick={()=>setFormData({...formData, action: 'amend'})}>Flag for review</button>
								</div>
							</div>

							{formData.action === 'amend' ? (
								<div className="row">
									<div className="col">
										<div className="form-group">
											<label>Reason</label>
											<p><small>Please explain the reason for flagging this product for review</small></p>
											<textarea className={fieldHasError("notes") ? "form-control is-invalid" : "form-control"} onChange={e => setFormData({...formData, notes: e.target.value})} name="notes" placeholder="Please explain what you need to amend" rows={5}></textarea>
										</div>
									</div>
								</div>
							): null}

							{formData.action !== null ? (
								<div className="row">
									<div className="col-6">
										<button className="btn btn-lg submit-btn" type="submit">
											{posted ? (
												<div className="btn-spinner-container">
													<div className="spinner-border" role="status">
														<span className="sr-only">Loading...</span>
													</div>
												</div>
											) : 'Submit'}
										</button>
									</div>
								</div>
							): null}

						</form>
					</div>


				</div>
			) : null}
		</div>
	)
}

export default NormalForm;
