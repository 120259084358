import React, { Component, useState, useEffect } from "react";
import {useFetch} from '../../shared/hooks';
import { navigate } from "@reach/router";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactTooltip from "react-tooltip";

import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'

import ImportantInfoCard from './shared/important-info-card'
import PriceCard from './shared/prices-card'

import ProductStatus from './shared/product-status'
import NormalForm from './shared/normal-form'
import FlagForReviewForm from './shared/flag-review-form'

const ProductsSuperPackage = ({id}) => {

	const [superPackage, setSuperPackage] = useState(null)
	const [form, setForm] = useState(null)

	//api call will be as follows
	const { data, loading } = useFetch( `/api/superpackage/view/${id}`, {
		superPackage: null,
		form: null,
	});

	useEffect( () => {
		setSuperPackage(data.superPackage);
		setForm(data.form);
	}, [data] );

	const renderForm = () => {

		if( form !==null && form.type.length > 0 ){
			switch( form.type ){
				case 'normal':
					return <NormalForm itemId={superPackage.id} itemType="superpackage" />
				case 'flagforreview':
					return <FlagForReviewForm itemId={superPackage.id} itemType="superpackage"/>
				default:
					return null;
			}
		}

		return null;

	}

	return (
		<div className="products-wrap product-item superpackage">

			<h1 className="section-h1">
				<Icon icon={ICONS.products} viewBox={ICONS.products.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
				Viewing package
			</h1>

			{loading ? (
				<div className="spinner-container dash-stats">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
			) : null}

			<div className="inner-content">

				<div className="row">
					<div className="col-sm-6 col-md-7">
						<a onClick={()=>navigate(-1)} className="back-link"><Icon icon={ICONS.chevronLeft} viewBox={ICONS.chevronLeft.viewBox} width={'14'} height={'14'} color={'#2c3343'} /> Products</a>
						{superPackage ? <> <span> / </span> <span>{superPackage.name}</span> </> : null}
					</div>
					<div className="col-sm-6 col-md-5">
						{superPackage !== null ? (
							<ProductStatus auditStatusId={superPackage.auditStatusId} type="package"/>
						) : null}
					</div>

				</div>

				{superPackage !== null ? (
					<div>

						<div className="row">
							<div className="col-12">

								<Tabs>

									<TabList>
										<Tab>Important Info</Tab>
										<Tab>Prices</Tab>
									</TabList>

									<TabPanel>
										<div className="row">
											<div className="col-12">
												<ImportantInfoCard item={superPackage} type="superpackage" />
											</div>
										</div>
									</TabPanel>

									<TabPanel>
										<div className="row">
											<div className="col-12">
												<PriceCard prices={superPackage.prices} type="superpackage"/>
											</div>
										</div>
									</TabPanel>

								</Tabs>

							</div>
						</div>

						<div className="row">
							{form !== null ? (
								<div className="col-sm-12">
									{renderForm()}
								</div>
							) : null}
						</div>

					</div>
				) : (
					<div className="spinner-container dash-stats">
	          <div className="spinner-border" role="status">
	            <span className="sr-only">Loading...</span>
	          </div>
	        </div>
				)}

			</div>
		</div>
	)
}

export default ProductsSuperPackage;
