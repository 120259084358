import React, {useState, useEffect} from 'react';
import { useLocation } from "@reach/router";
import DataTable from "../shared/datatable"
import MUIDataTable from "mui-datatables";
import {navigate} from "@reach/router";
import Alert from "../shared/alert";

const Search = () => {

	const [term, setTerms] = useState('');
	const [results, setResults] = useState([]);
	const [count, setCount] = useState(0);
	const location = useLocation();

	useEffect( () => {
		setTerms(location.state.searchTerm);
		setResults(location.state.searchResults);
		setCount(location.state.searchCount);
	},[location]);


	let options = {
		disableToolbarSelect: true,
		sort: false,
		filter:false,
		search: false,
		print: false,
		download: false,
		selectableRows: 'none',
		responsive: 'stacked',
		viewColumns: false,
		rowsPerPageOptions:	[5,10,15,20],
		textLabels: {
			body: {
				noMatch: "Sorry, no matching records found",
				columnHeaderTooltip: column => `Sort for ${column.label}`
			},
			pagination: {
				rowsPerPage: "Rows:",
				displayRows: "out of",
			}
		},
		onRowClick: (rowData, foo) => {
			navigate(`/requests/booking/${rowData[6]}/${rowData[7]}`);
		}
	};

	const columns = [
		{ label: 'ID',      name: "id", options: { filter: false, sort: true, display: false } },
		{ label: 'Ref',     name: "ref", options: { filter: false, sort: true,
				customBodyRender: (value, tableMeta, updateValue) => (
					<span className="booking-ref">{value}</span>
				)
			}},
		{ label: 'Status',  name: "statusType", options: { filter: true, sort: true,
				customBodyRender: (value, tableMeta, updateValue) => (
					<span className={value.toLowerCase() + ' status'}>{tableMeta.rowData[9]}</span>
				)}
		},
		{ label: 'Date',    name: "date", options: { filter: false, sort: true } },
		{ label: 'Client',  name: "client", options: { filter: false, sort: true } },
		{ label: 'Product', name: "product", options: { filter: false, sort: true } },
		{ label: 'Type',    name: "type", options: { display: false  } },
		{ label: 'BookingItemId', name: "bookingItemId", options: { display: false  } },
		{ label: 'Location',name: "location", options: { filter: true, sort: true } },
		{ label: 'StatusText',  name: "statusText", options: { display: false} }
	];


	let title = count==1 ? `${count} result found for "${term}"` : `${count} results found for "${term}"`;

	return (
		<div>
			<div>

				<div className="booking-wrap">
					<h1 className="section-h1">
						Search results
					</h1>

					<div className="inner-content">
						<p></p>

						{count > 0 ? (
							<DataTable title={title} data={results} options={options} columns={columns}/>

						) : (
							<div>
								<Alert title={`No results found for "${term}"`} message={"Searches can be done either by booking reference, or by the clients name."} type={"info"}/>
							</div>
						)}

					</div>

				</div>

			</div>


		</div>
	)

}

export default Search;
