import React from 'react';

const HotelForm = ({itemType, formType, data, setFormData, fieldHasError, handleSubmit, doSubmitForm, posted}) => {

	let showMain = data.action;
	const showSubmit = data.action !== '';
	let reason = false;
	const showAccept = formType !== 'price';

	if( showMain ){
		switch( data.action  ){
			case 'decline':
				reason = "<label>Reason</label><p>If its already fully booked for these dates, or another reason which would prevent bookings going ahead on this date let us know and we'll prevent further requests coming through on this date</p>";
				break;
			case 'maybe':
				if( formType === 'price' ){
					reason = "<label>Details</label>" +
						"<p>Please provide details of what rooms you have available covering these days. Make sure you include <strong>costs</strong> and <strong>room types</strong></p>" +
						"<p>eg. 6x Twins @£50 per person per night includes breakfast</p>"
				} else {
					reason = "<p>If you have any other booking conditions, please enter them below:</p>";
				}
		}

	}

	return (
		<div className="card card p-2 mt-3 p-md-4">

			<h5 className="card-title">Accept or Decline this request - please complete your details below</h5>
			<form name="avail" method="post" onSubmit={handleSubmit}>

				<div className="form-flex-container form-input-wrap">

					<div className="actions-wrap">
						<p>Please choose how you would like to proceed:</p>
						{showAccept ? (
							<button type="button" className={`btn btn-outline-success mr-4 ${data.action === 'accept' ? 'bg-success text-white' : ''}`} onClick={() => setFormData({...data, action: 'accept'})}>Accept</button>
						):null}
						<button type="button" className={`btn btn-outline-warning mr-4 ${data.action === 'maybe' ? ' bg-warning text-white' : ''}`} onClick={() => setFormData( {...data, action: 'maybe' })}>Flag as conditional</button>
						<button type="button" className={`btn btn-outline-danger mr-4 ${data.action === 'decline' ? ' bg-danger text-white' : ''}`} onClick={() => setFormData( {...data, action: 'decline' })}>Decline</button>
					</div>

					{showMain &&
						<React.Fragment>
							<div className="form-group">
								<label>Your first name</label>
								<input className={`form-control ${fieldHasError("firstname") ? "is-invalid" :""}`} type="text" name="firstname" value={data.firstname} onChange={e => setFormData( {...data, firstname: e.target.value })} placeholder="Please enter your firstname" />
							</div>

							<div className="form-group">
								<label>Your surname</label>
								<input className={`form-control ${fieldHasError("surname") ? "is-invalid" :""}`} type="text" name="surname" value={data.surname} onChange={e => setFormData( {...data, surname: e.target.value })}  placeholder="Please enter your surname"/>
							</div>

							<div className="form-group">
								<label>Your email</label>
								<input className={`form-control ${fieldHasError("email") ? "is-invalid" : ""}`} type="text" name="email" value={data.email} onChange={e => setFormData( {...data, email: e.target.value })}  placeholder="Please enter your email"/>
							</div>

							{reason ? (
							<div className="form-group">
								<div dangerouslySetInnerHTML={{__html:reason}}></div>
								<textarea className={fieldHasError("reason") ? "form-control is-invalid" : "form-control"} name="reason" value={data.reason} onChange={e => setFormData( {...data, reason: e.target.value })} rows={4}></textarea>
							</div>
							):null}
						</React.Fragment>
					}

					{showSubmit &&
						<button className="btn btn-lg submit-btn" type="button" onClick={handleSubmit} disabled={posted}>
							{posted ? (
								<div className="btn-spinner-container">
									<div className="spinner-border" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								</div>
							) : "Submit"}
						</button>
					}

				</div>
			</form>
		</div>
	);
};

export default HotelForm;