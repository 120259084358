import React from "react";
import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'
import ClientDetails from './client-details'

const HotelCard = ({booking, notes}) => {

	if( notes===undefined ){
		notes = '';
	}

	return (
		<div>

			<ClientDetails booking={booking} showVenue={true} venue={booking.hotel.venue} />


			<div className="activity-details card p-2 p-md-4 mb-4">
				<div className="details__header">
					<h5>{booking.hotel.name}</h5>
					{booking.status !== null ? (
						<div className="status-container">
							<div className="flex-card-container">
								<div className={`flex-item-2 status ${booking.status.type}`}>
									{booking.status.label}
								</div>
								<div className="flex-item-2">
									<span className="status__name">By {booking.status.by}</span>
									<span className="status__date"> {booking.status.date}</span>
								</div>
							</div>
							<div className="status__message">
								<p>{booking.status.description}</p>
							</div>
						</div>
					) : null}
				</div>

				{booking.hotel.displayDetails ? (
				<table className="table table-responsive-sm">
					<thead className="thead-dark">
					<tr>
						<th scope="col">Date</th>
						<th scope="col">Type</th>
						<th scope="col">People</th>
						<th scope="col">Rooms</th>
						<th scope="col">Cost (per room)</th>
					</tr>
					</thead>
					<tbody>
					{booking.hotel.nights.map( (night, index) =>
						<React.Fragment key={`bh-row-${index}`}>
							{night.rooms.map((room) => (
								<tr key={room.id}>
									<td>{night.date}</td>
									<td>{room.type}</td>
									<td>{room.pax}</td>
									<td>{room.rooms}</td>
									<td>{room.cost.currencyPrefix ? (
										<b><span dangerouslySetInnerHTML={{ __html: room.cost.currency}} />{room.cost.amount}</b>
									) : (
										<b>{room.cost.amount}<span dangerouslySetInnerHTML={{ __html: room.cost.currency }} /></b>
									)}</td>
								</tr>
							))}
						</React.Fragment>
					)}
					</tbody>
				</table>
				) : (
					<table className="table table-responsive-sm">
						<thead className="thead-dark">
						<tr>
							<th scope="col">Hotel</th>
							<th scope="col">Date</th>
							<th scope="col">People</th>
						</tr>
						</thead>
						<tbody>
						{booking.hotel.nights.map((night, index) =>
							<tr key={night.id}>
								<td>{booking.hotel.name}</td>
								<td>{night.date}</td>
								<td>{night.people}</td>
							</tr>
						)}
						</tbody>
					</table>
				)}
				{booking.hotel.allocation && booking.hotel.allocation > 0 ? <p>This booking will use {booking.hotel.allocation} rooms from our allocation</p> :null}

				{notes.length > 0 ? (
					<div>
						<div className="details__header mt-3">
							<h5 className="status-title">Notes</h5>
						</div>
						<p className="client-phone" dangerouslySetInnerHTML={{ __html: notes}} />
					</div>
				):null}


			</div>

		</div>
	)
}

export default HotelCard;
