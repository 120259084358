import React, { Component, useContext, useState } from "react";
import { slide as Menu } from "react-burger-menu"
import { Link } from "@reach/router"
import { store } from "../../../context/store";


import Icon from '../../shared/icon-component'

import { navStructure } from "./nav_structure"

// make a new context
const MyContext = React.createContext();

// create the provider
const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false)

  return (
    <MyContext.Provider value={{
      isMenuOpen: menuOpenState,
      toggleMenu: () => setMenuOpenState(!menuOpenState),
      stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen)
    }}>
      {props.children}
    </MyContext.Provider>
  )
}

const Navigation = () => {

  const ctx = useContext(MyContext)
  const [ state, setState ] = useContext( store )

  return (
    <div className="mobile-nav__header">
      <Menu right pageWrapId={"body-wrap"} isOpen={ctx.isMenuOpen} onStateChange={(state) => ctx.stateChangeHandler(state)}>
        <div className="nav-wrap">
          <div className="nav-supplier-info">
            <div className="avatar">{state.initials}</div>
            <div className="supplier-name">{state.name}</div>
            <div className="supplier-email">{state.email}</div>
          </div>
          <ul>
            {navStructure().map((link, index) => (

              <li
                key={`nav-mobile-item-${index}`}
                style={{
                  listStyleType: `none`,
                  padding: `1rem`,
                }}
                >
                {link.path == 'no-link' ? (
                    <span className="navbar-item"><strong>{link.title}</strong></span>
                  ) : (
                    <Link className="navbar-item" to={link.path} onClick={ctx.toggleMenu}>	<Icon icon={link.icon} viewBox={link.viewBox} width={'16'} height={'16'} color={'#939AA6'} />{link.title} { link.count != undefined && link.count > 0 ? ( <span className="badge">{link.count}</span> ) : null }</Link>
                  )
                }
              </li>

              ))}
            <li className="nav-logout">
              <a className="navbar-item" href="/users/logout">Log out</a>
            </li>
            </ul>
        </div>
      </Menu>
    </div>
  )
}

const MobileNavigation = () => {
  return (
    <MyProvider>
      <div>
        <Navigation />
      </div>
    </MyProvider>
  )
}

export default MobileNavigation;
