import React, {useState} from "react";
import {useFetch} from "../../shared/hooks";
import {Link, navigate, redirectTo} from "@reach/router";

import { ICONS } from "../shared/icons"
import Icon from "../shared/icon-component"

import DataTable from "../shared/datatable"
import MUIDataTable from "mui-datatables";

const Requests = () => {

	const initialData = {
		bookings: []
	};

	const { data, loading } = useFetch( '/api/bookingrequests', initialData );

	let options = {
		filterType: 'dropdown',
		selectableRows: 'none',
		responsive: 'stacked',
		viewColumns: false,
		rowsPerPageOptions:	[5,10,15,20],
		textLabels: {
			body: {
				noMatch: "Congratulations, there are no bookings to action",
				columnHeaderTooltip: column => `Sort for ${column.label}`
			},
			pagination: {
				rowsPerPage: "Rows:",
				displayRows: "out of",
			}
		},
		onRowClick: (rowData) => {
			navigate(`/requests/booking/${rowData[7]}/${rowData[9]}`);
		},
		setRowProps: (row) => {
			if( row[2].props.className === "pending status" ) {
				return {
					className: "pending-row"
				}
			}
		}
	};

	const columns = [
		{ label: 'ID',      name: "id", options: { filter: false, sort: true, display: false } },
		{ label: 'Ref',     name: "ref", options: { filter: false, sort: true,
			customBodyRender: (value, tableMeta, updateValue) => (
				<span className="booking-ref">{value}</span>
			)
		}},
		{ label: 'Status',  name: "statusType", options: { filter: true, sort: true,
			customBodyRender: (value, tableMeta, updateValue) => (
				<span className={value.toLowerCase() + ' status'}>{tableMeta.rowData[3]}</span>
			)}
		},
		{ label: 'StatusText',  name: "statusText", options: { filter: true, sort: true, display: false} },
		{ label: 'Date',    name: "date", options: { filter: false, sort: true } },
		{ label: 'Client',  name: "client", options: { filter: false, sort: true,
			customBodyRender: (value, tableMeta, updateValue) => (
				<span className="client-name">{value}</span>
			)
		 }},
		{ label: 'Product', name: "product", options: { filter: false, sort: true,
			customBodyRender: (value, tableMeta, updateValue) => (
				<span className="product-name">{value}</span>
			)
		 }},
		{ label: 'Type',    name: "type", options: { filter: true, sort: true, display: false  } },
		{ label: 'Location',name: "location", options: { filter: true, sort: true } },
		{ label: 'Request', name: "bookingItemId", options: { filter: false, sort: true, display: false  } }
	];

	return (
		<div>

			<div className="booking-wrap">
				<h1 className="section-h1">
					<Icon icon={ICONS.bookingRequest} viewBox={ICONS.bookingRequest.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
					Booking Requests
				</h1>

				<div className="inner-content">

					{loading ? (
						<div className="spinner-container">
							<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					) : (
						<DataTable title={"Booking requests"} data={data.bookings} options={options} columns={columns}/>
					)}

				</div>

			</div>

		</div>

	)
};

export default Requests;
