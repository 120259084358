import React, {useState} from 'react';
import { navigate } from "@reach/router";

const History = ({items}) => {

	return (

		<div className="details card p-4 mt-3 mb-3">

			<div className="details__header">
				<h5>Availabilty check history</h5>
			</div>

			{items.map( (item) => (
					
				<div key={`history-${item.id}`} className="history">
					<div className="header flex flex-row items-center justify-between">
						<div>
							{item.by != null && item.by.name != null ? item.by.name : ''}
							<div>{item.date}</div>
						</div>
						<span className={`${item.statusType} status`}>{item.statusText}</span>
					</div>
					
					{item.times.length > 0 ? (
						<div className="flex flex-row items-center gap-2 times">
							<span>Times:</span>
							{item.times.map( (time, t) => (
								<span className="label" key={`time-${t}`}>{time}</span>
							))}		
						</div>
					):null}
					
					{item.notes != null ? (
						<div><small><i>{item.notes}</i></small></div>
					):null}
				</div>
				
			))}

		</div>
	)

}
export default History;