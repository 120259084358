import React, {useState} from 'react';
import { navigate } from "@reach/router";

const AdditionalItems = ({items}) => {

	return (

		<div className="details card p-4 mt-3 mb-3">

			<div className="details__header">
				<h5>Other items on this booking</h5>
			</div>

			<table className="MuiTable-root MUIDataTable-tableRoot-4">
			<tbody className="MuiTableBody-root">
			{items.map( (item) => (
				<tr className="MuiTableRow-root" key={`additional-item-${item.id}`}>
					<td className="MuiTableCell-root MuiTableCell-body">
						<a onClick={() => {
							const path = `/requests/booking/event/${item.id}`
							window.location.replace(path);
						}}
						>
							{item.product}
						</a>
					</td>
					<td className="text-right MuiTableCell-root MuiTableCell-body">
						<span className={`${item.statusType} status`}>{item.statusText}</span>
					</td>
				</tr>
			))}
			</tbody>
			</table>

		</div>
	)

}
export default AdditionalItems;