import React, {Component} from "react";

import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'


const ActivityCard = ({booking, notes}) => {

	if( notes === null || notes === undefined ){
		notes = '';
	}

	return (
		<div>

			<div className="details card p-4 mt-3 mb-3">
				<div className="details__header">
					<h5>Client Details</h5>
				</div>

				<div className="flex-card-container">
					<p><Icon icon={ICONS.user} viewBox={ICONS.user.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Client</span></p>
					<p className="client-name flex-item-2 text-right">{booking.client.name}</p>
				</div>

				{booking.client.phone ? (
					<div className="flex-card-container">
						<p><Icon icon={ICONS.phone} viewBox={ICONS.phone.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Phone</span></p>
						<p className="client-phone text-right flex-item-2"><small>{booking.client.phone}</small></p>
					</div>
				):null}

				{booking.client.email ? (
				<div className="flex-card-container">
					<p><Icon icon={ICONS.mail} viewBox={ICONS.mail.viewBox} width={'30'} height={'20'} color={'#000000'} /><span className="details__item">Email</span></p>
					<p className="client-phone text-right flex-item-2"><small>{booking.client.email}</small></p>
				</div>
				):null}

				{ booking.client.email || booking.client.phone ? (
					<div className="flex-card-container">
						<p>
							Please note, the customer details are being shared, should you need to get hold of 
							the customer over the departure weekend. Please do not contact the customer prior to this
						</p>
					</div>
				):null}

			</div>

			<div className="details card p-4">
				<div className="details__header">
					<h5 className="status-title">{booking.event.name}</h5>

					{booking.status !== null ? (
						<div className="status-container">
							<div className="flex-card-container">
								<div className={`flex-item-2 status ${booking.status.type}`}>{booking.status.label}</div>
								<div className="flex-item-2">
									<span className="status__name">By {booking.status.by}</span>
									<span className="status__date">{booking.status.date}</span>
								</div>
							</div>
							<div className="status__message">
								<p>{booking.status.description}</p>
							</div>
						</div>
					) : null}
				</div>

				<div className="flex-card-container">
					<p className="flex-item-2"><Icon icon={ICONS.users} viewBox={ICONS.users.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">People</span></p>
						<p className="client-phone flex-item-2 text-right">{booking.event.pax} people</p>
				</div>

				{booking.event.cost ?
				<div className="flex-card-container">
						<p className="flex-item-2">
							<Icon icon={ICONS.coin} viewBox={ICONS.coin.viewBox} width={'16'} height={'16'} color={'#000000'} />
							<span className="details__item">Price</span>
						</p>
						<p className="client-phone flex-item-2 text-right">
							{booking.event.cost.currencyPrefix ? (
								<b><span dangerouslySetInnerHTML={{ __html: booking.event.cost.currency}} />{booking.event.cost.amount}</b>
							) : (
								<b>{booking.event.cost.amount}<span dangerouslySetInnerHTML={{ __html: booking.event.cost.currency }} /></b>
							)}
							{booking.event.cost.isPerPerson ? "pp" : " total"}
						</p>
				</div>
				: null }

				{booking.event.date ?
				<div className="flex-card-container">
					<p className="flex-item-2"><Icon icon={ICONS.calendarDark} viewBox={ICONS.calendarDark.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Date</span></p>
					<p className="client-phone flex-item-2 text-right">{booking.event.date}</p>
				</div>
				: null }

				{booking.event.time ?
				<div className="flex-card-container">
					<p className="flex-item-2"><Icon icon={ICONS.time} viewBox={ICONS.time.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Time</span></p>
					<p className="client-phone flex-item-2 text-right">
						{booking.event.timeConfirmed ? booking.event.time : booking.event.timePreference}
					</p>
				</div>
				:null }

				{booking.event.venue ? (
					<div className="flex-card-container">
						<p className="flex-item-2"><Icon icon={ICONS.location} viewBox={ICONS.location.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Location</span></p>
						<p className="client-phone flex-item-2 text-right">
							{booking.event.venue.map( (address, i) => <span key={`adress-line-${i}`}>{address}<br/></span>)}
						</p>
					</div>
				):(
					<div className="flex-card-container">
						<p className="flex-item-2"><Icon icon={ICONS.location} viewBox={ICONS.location.viewBox} width={'16'} height={'16'} color={'#000000'} /><span className="details__item">Location</span></p>
						<p className="client-phone flex-item-2 text-right">
							{booking.location}
						</p>
					</div>
				)}

				{notes.length > 0 ? (
					<div>
						<div className="details__header mt-3">
							<h5 className="status-title">Notes</h5>
						</div>
						<p className="client-phone" dangerouslySetInnerHTML={{ __html: notes}} />
					</div>
				):null}

			</div>

		</div>
	);
}

export default ActivityCard
