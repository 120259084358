import { useEffect, useState } from "react";
import { handleApiError } from "../context/api_error";

export const useFetch = ( url, initialData=null ) => {
    
	const [data, setData] = useState(initialData);
	const [loading, setLoading] = useState(true);

	const {error, message, raise, reset} = handleApiError();

	const resetData = () => {
		setData(initialData);
		fetchData();
	}

	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {

		setLoading(true);
		reset();

		const response = await fetch(url);

		if( response.status == 200 ) {
			const data = await response.json();
			setLoading(false);
			setData(data);
		} else {
			switch( response.status ) {
				case 403:
					//forbiden
					history.pushState( null, null, '/' );
					window.location.replace( '/' );
					break;
				case 422:
					//std error
					const data = await response.json();
					raise( data.message );
					break;
				default:
					//any other error
					raise("We are currently experiencing some problems, if problem persists please contact us by phone");
					break;
			}
		}
	};
  
    return { data, loading, error, resetData };
};

export const usePost = ( url ) => {
    
	const [responseData, setResponseData] = useState(null);
	const [posted, setPosted] = useState(false);
	const [errorFields, setErrorFields] = useState(null);

	const {error, message, raise, reset} = handleApiError();

	const post = async( postData ) => {

		//Clear error messages
		reset();

		setPosted(true);
		const response = await fetch(url, {
            method:'post', 
            body:postData, 
        });
		setPosted(false);

		if( response.status == 200 ) {
			const data = await response.json();
			setResponseData(data);
		} else {
			switch( response.status ) {
				case 403:
					//forbiden
					history.pushState(null, null, '/' );
					window.location.replace( '/' );
					break;
				case 422:
					//std error
					const data = await response.json();
					setErrorFields(data.field);
					raise( data.message );
					break;
				default:
					//any other error
					raise( 'Something serious seems to have gone wrong!' );
					break;
			}
		}

	};

	const resetPost = () => {
		setResponseData(null);
		setPosted(false);
		setErrorFields(null);
	};

	return [ post, { posted, error, responseData, errorFields }, resetPost ];

};

