import React from "react";
import MUIDataTable from "mui-datatables";
import {Typography} from '@material-ui/core';

class DataTable extends React.Component {

	constructor(props) {
		super(props);
			this.state = {};
			this.columns = props.columns ? props.columns : [];
			this.options = props.options ? props.options : {};

			//string title default to h6 variant - so force other types to match style
			if( typeof props.title !== 'string' ){
				this.title = this.wrap(props.title);
			} else {
				this.title = props.title;
			}
	}


	wrap(content){
		return (
			<Typography variant="h6">
				{content}
			</Typography>
		)
	}

	render() {
		return (
			<div>
				<MUIDataTable
					title={this.title}
					data={this.props.data}
					columns={this.columns}
					options={this.options}
				/>
			</div>
		)
	}
}
export default DataTable;
