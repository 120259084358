import React from 'react';
import { ICONS } from '../shared/icons';
import Icon from '../shared/icon-component';

const Alert = ({title, message, type, close}) => {

	if( typeof type !== 'string' || type === '' ){
		type = 'success';
	}

	if( typeof message !== 'string' || message === '' ){
		message = 'Your changes have been saved';
	}

	if( typeof title !== 'string' || title === '' ){
		title = 'Congratulations';
	}

	let myClassName = '';
	let icon = '';
	switch(type){
		case 'info':
			icon = ICONS.info;
			myClassName = 'alert alert-info';
			break;
		case 'danger':
		case 'error':
			myClassName = 'alert alert-danger';
			icon = ICONS.warning;
			break;
		default:
			myClassName = 'alert alert-success';
			icon = ICONS.success;
			break;
	}

	return (
		<div>
			<div className={myClassName}>
				<div className="row">
					<div className="col-2 col-md-1">
						<Icon icon={icon} viewBox={ICONS.success.viewBox} width={'40'} height={'40'} color={'#155724'} />
					</div>
					<div className="col-10 col-md-11">
						<h4>{title}</h4>
						<p>{message}</p>
					</div>
				</div>
			</div>
		</div>
	)

}

export default Alert;
