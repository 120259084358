import React, {useState, useEffect} from 'react';
import {usePost} from '../../../shared/hooks';
import Alert from '../../shared/alert';

import { ICONS } from '../../shared/icons'
import Icon from '../../shared/icon-component'

const FlagForReview = ({itemType, itemId}) => {

	const [showForm, setShowForm] = useState(false);
	const [showToggle, setShowToggle] = useState(true);
	const [success, setSuccess] = useState(false);
	const [formData, setFormData] = useState({firstname: '', surname: '', email: '', notes: ''});
	const [formErrors, setFormErrors] = useState([]);
	const [post, { posted, error, responseData, errorFields }, resetPost ] = usePost('/api/products/updateproduct' );

	const handleSubmit = (e) => {
		e.preventDefault();

		if( validate() ){
			let postData = new FormData();
			postData.append("itemType", itemType );
			postData.append("itemId", itemId );
			postData.append("action", "amend");
			postData.append("firstname", formData.firstname );
			postData.append("surname", formData.surname );
			postData.append("email", formData.email );
			postData.append("notes", formData.notes );
			post( postData );
		} else {
			//TODO - add error alert
		}

	}

	useEffect( () => {
		if( responseData !== null && responseData.success ){
			setSuccess(responseData.success);
			setShowForm(false);
			setShowToggle(false);
		}
	},[responseData])

	const validate = () => {

		let errors = [];

		if( !(formData.firstname.length > 0) ){
			errors.push('firstname');
		}

		if( !(formData.surname.length > 0) ){
			errors.push('surname');
		}

		if( !(formData.email.length > 0) ){
			errors.push('email');
		}

		if( !(formData.notes.length > 0) ){
			errors.push('notes');
		}

		setFormErrors(errors);

		return errors.length==0;

	}

	const fieldHasError = (key) => {
		if( errorFields!==null && key==errorFields ){
			return true;
		} else {
			return !!(formErrors.find( i => i==key ));
		}
	}

	return (
		<div>

			{success ? (<Alert type='success' message={success}/>) : null}

			{showForm ? (
				<div className="details card">

					<div className="dataset__header">
						<div className="dataset__header--title">

							<div className="row">
								<div className="col-9">
									<h4>Review This Product</h4>
								</div>
								<div className="col-3">
									<button className="btn btn-outline-dark btn-sm float-right" type="button" onClick={()=>setShowForm(false)}>Hide</button>
								</div>
							</div>

						</div>
					</div>

					<div className="persistent-form">
						<form onSubmit={handleSubmit} name="important-info" method="post">
							<div className="row">
								<div className="col-sm-12 col-md-6 col-lg-4">
									<div className="form-group">
										<label>Your first name</label>
										<input className={`form-control ${fieldHasError("firstname") ? "is-invalid" : ""}`} onChange={e => setFormData({...formData, firstname: e.target.value})} value={formData.firstname} type="text" placeholder="Please enter your firstname" />
									</div>
								</div>

								<div className="col-sm-12 col-md-6 col-lg-4">
									<div className="form-group">
										<label>Your surname</label>
										<input className={`form-control ${fieldHasError("surname") ? "is-invalid" : ""}`} onChange={e => setFormData({...formData, surname: e.target.value})} value={formData.surname} type="text" name="surname" placeholder="Please enter your surname"/>
									</div>
								</div>

								<div className="col-sm-12 col-md-6 col-lg-4">
									<div className="form-group">
										<label>Your email</label>
										<input className={`form-control ${fieldHasError("email") ? "is-invalid" : ""}`} onChange={e => setFormData({...formData, email: e.target.value})} value={formData.email} type="text" name="email" placeholder="Please enter your email"/>
									</div>
								</div>

								<div className="col">
									<div className="form-group">
										<label>Reason</label>
										<p><small>Please explain the reason for flagging this product for review</small></p>
										<textarea className={`form-control ${fieldHasError("notes") ? "is-invalid" : ""}`} onChange={e => setFormData({...formData, notes: e.target.value})} value={formData.notes} name="notes" placeholder="Please explain what you need to amend" rows={5}></textarea>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-6">
									<button className="btn btn-lg submit-btn" type="submit">
										{posted ? (
											<div className="btn-spinner-container">
												<div className="spinner-border" role="status">
													<span className="sr-only">Loading...</span>
												</div>
											</div>
										) : 'Submit'}
									</button>
								</div>
							</div>

						</form>
					</div>

				</div>
			) : (
				<div>
					{showToggle ? (<button className="btn btn-amend" type="button" onClick={()=>setShowForm(true)}>Review this product</button>):null}
				</div>
			)}
		</div>
	)
}

export default FlagForReview;
