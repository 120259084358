import React, { Component, useState, useEffect } from "react";

import { ICONS } from '../../shared/icons'
import Icon from '../../shared/icon-component'
import ReactTooltip from "react-tooltip";
import Modal from 'react-modal';

import AdditionalInfoCard from './additional-info-card'

const ImportantInfoCard = ({item, type}) => {

	const [modalIsOpen,setIsOpen] = useState(null);

	const openModal = (index) => {
		setIsOpen(index);
	}
	const closeModal = () => {
		setIsOpen(null);
	}

	const customStyles = {
		overlay: { zIndex: 999 },   //not sure what this should be? 999 seems excessive
		content : {
			top                   : '50%',
			left                  : '50%',
			right                 : 'auto',
			bottom                : 'auto',
			marginRight           : '-50%',
			transform             : 'translate(-50%, -50%)'
		}
	};

	return (
		<div>

				{type=='event' ? (
					<div className="details card p-4 mt-3 mb-3">
						<div className="details__header">
							<h4 className="details__header--title">Important Information </h4>
						</div>
						<div className="row">
							<div className="col-12 col-md-3">
								<h5>Location</h5>
								<p>{item.location}</p>
							</div>
							<div className="col-12 col-md-3">
								<h5>Cancel Notice</h5>
								<p>{item.cancelNotice} Days</p>
							</div>
							<div className="col-12 col-md-3">
								<h5>Period</h5>
								<p>{item.period}</p>
							</div>
							<div className="col-12 col-md-3">
								<h5>Require Confirmation by Supplier <span className="info-icon" data-tip data-event='click focus' data-for="confirmation"><Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'25'} height={'25'} color={'#000000'} /></span></h5>
								<ReactTooltip id="confirmation" type="light" globalEventOff='click' effect="solid" aria-haspopup='true'>
									<h5>Require Confirmation by Supplier</h5>
									<p>Whether or not we need confirmation (from yourself) when booking one of your products</p>
								</ReactTooltip>
								<p>{item.requireBookingConfirmation ? "Yes" : "No"}</p>
							</div>
						</div>
					</div>
				) : null}

				{type=='hotel' ? (
					<div className="details card p-4 mt-3 mb-3">
						<div className="details__header">
							<h4 className="details__header--title">Important Information </h4>
						</div>
						<div className="row">
							<div className="col-12 col-md-3">
								<h5>Location</h5>
								<p>{item.location}</p>
							</div>
							<div className="col-12 col-md-3">
								<h5>Breakfast offered</h5>
								<p>{item.breakfast}</p>
							</div>
							<div className="col-12 col-md-3">
								<h5>Star rating</h5>
								<p>{item.starRating}</p>
							</div>
							<div className="col-12 col-md-3">
								<h5>Cancel notice</h5>
								<p>{item.cancelNotice}</p>
							</div>
							<div className="col-12 col-md-3">
								<h5>Release date</h5>
								<p>{item.releaseDate}</p>
							</div>
							<div className="col-12 col-md-3">
								<h5>Distance from city centre</h5>
								<p>{item.distance} miles</p>
							</div>
						</div>
					</div>
				) : null}

				{type=='superpackage' ? (
					<div className="row">
						<div className="col-sm-12 col-md-8">
							<div className="details card p-4 mt-3 mb-3">
								<div className="details__header">
									<h4 className="details__header--title">Important Information </h4>
								</div>
								<div className="row">
									<div className="col-12 col-md-6">
										<h5>Location</h5>
										<p>{item.location}</p>
									</div>
									<div className="col-12 col-md-6">
										<h5>Minimum number</h5>
										<p>{item.minimum}</p>
									</div>
									<div className="col-12 col-md-6">
										<h5>Cancel notice</h5>
										<p>{item.cancelNotice}</p>
									</div>
									<div className="col-12 col-md-6">
										<h5>Require Confirmation by Supplier <span className="info-icon" data-tip data-event='click focus' data-for="confirmation"><Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'25'} height={'25'} color={'#000000'} /></span></h5>
										<ReactTooltip id="confirmation" type="light" globalEventOff='click' effect="solid" aria-haspopup='true'>
											<h5>Require Confirmation by Supplier</h5>
											<p>Whether or not we need confirmation (from yourself) when booking one of your products</p>
										</ReactTooltip>
										<p>{item.requireBookingConfirmation ? "Yes" : "No"}</p>
									</div>
									<div className="col-12 col-md-6">
										<h5>Require Availability Check with Supplier<span className="info-icon" data-tip data-event='click focus' data-for="availability"><Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'25'} height={'25'} color={'#000000'} /></span></h5>
										<ReactTooltip id="availability" type="light" globalEventOff='click' effect="solid" aria-haspopup='true'>
											<h5>Require Availibility Check with Supplier</h5>
											<p>Whether or not we are required to check availability prior to booking</p>
										</ReactTooltip>
										<p>{item.requireAvailabilityCheck ? "Yes" : "No"}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-12 col-md-4">
							<div className="details card p-4 mt-3 mb-3">
								<div className="details__header">
									<h4 className="details__header--title">Package Includes</h4>
								</div>
								<div className="row">
									<div className="col-12">
										<ul>
											{item.items.map((item, index)=>
												<div key={`package-item-${item.type}-${item.id}`}>
													<li>{item.name} <span className="open-modal" onClick={()=>openModal(index)}><Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'25'} height={'25'} color={'#000000'} /></span></li>
													<Modal
														isOpen={modalIsOpen==index}
														onRequestClose={closeModal}
														style={customStyles}
														contentLabel="Event Info Modal"
													>
														<AdditionalInfoCard dataset={item.dataset}/>
													<button className="close" onClick={closeModal}><Icon icon={ICONS.cross} viewBox={ICONS.cross.viewBox} width={'30'} height={'30'} color={'#000000'} /></button>
												</Modal>
												</div>
											)}

										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}

			</div>
	);
}

export default ImportantInfoCard
