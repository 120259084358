import React, { Component, useState, useEffect, useCallback } from "react";
import {useFetch, usePost} from '../../shared/hooks'
import { store } from "../../context/store";
import DataTable from '../shared/datatable';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'
import {navigate} from "@reach/router/index";
import { handleApiError } from "../../context/api_error";
import { Link } from "@reach/router"
import Alert from '../shared/alert';
import {useTotals} from "../../context/totals";

const Confirmations = () => {

	const {totals, dispatch } = useTotals();
	const { data, loading, fetchError, resetData } = useFetch( '/api/finalconfirmations', {events: [], hotels: [], packages: []} );
	const [ post, { posted, error, responseData, errorFields }] = usePost('/api/finalconfirmations/update' );
	const [ mySelectedItems, setMySelectedItems ] = useState([]);

	const {apiError, message, raise, reset} = handleApiError();

	const [formData, setFormData] = useState({
		firstname:  '',
		surname:    '',
		email:      ''
	});

	const columns = [
		{ label: 'ID',      name: "id", options: { filter: false, sort: true, display: false } },
		{ label: 'Ref',     name: "ref", options: { filter: false, sort: true,
				customBodyRender: (value, tableMeta, updateValue) => (
					<span className="booking-ref">{value}</span>
				)
			}},
		{ label: 'Date',        name: "date", options: { filter: false, sort: true } },
		{ label: 'Time',        name: "time", options: { filter: false, sort: true } },
		{ label: 'Client',      name: "client", options: { filter: false, sort: true,
			customBodyRender: (value, tableMeta, updateValue) => (
				<span className="client-name">{value}</span>
			)
		 } },
		{ label: 'Group',       name: "pax", options: { filter: false, sort: true } },
		{ label: 'Product',     name: "product", options: { filter: false, sort: true,
			customBodyRender: (value, tableMeta, updateValue) => (
				<span className="product-name">{value}</span>
			)
		 } },
		{ label: 'Type',        name: "type", options: { filter: true, sort: true, display: false } },
		{ label: 'Location',    name: "location", options: { filter: true, sort: true } },
		{ label: 'BookingItemId',name: "bookingItemId", options: { filter: false, sort: false, display: false} },
		{ label: 'StatusText',  name: "statusText", options: { filter: true, sort: true, display: false} }
	];

	const handleRemoveItem = (index) => {
	 	const remove = index.target.getAttribute("remove")
		setMySelectedItems(mySelectedItems.filter(item => item.key !== remove));
	};

	const handleRemoveItems = () => {
		setMySelectedItems([]); //reset form
	};

	const toggleItem = (type, allSelectedRows) => {

		//Take a copy of any (if any) other types otherwise we would override them
		let copy = mySelectedItems.filter( (item) => item.type !== type);

		let selected = [];
		allSelectedRows.map( (row) => {

			let selectedItem = null;
			switch( type ){
				case 'event':
					selectedItem = data.events[row.dataIndex];
					break;
				case 'hotel':
					selectedItem = data.hotels[row.dataIndex];
					break;
				case 'superpackage':
					selectedItem = data.packages[row.dataIndex];
			}

			if( typeof selectedItem === 'object' && selectedItem !== null ){
				selected.push({
					id: selectedItem.id,
					ref: selectedItem.ref,
					type: selectedItem.type,
					itemId: selectedItem.bookingItemId,
					key: `conf-${selectedItem.type}-${selectedItem.bookingItemId}`,
					name: selectedItem.product
				});
			}

		});

		setMySelectedItems( [...copy, ...selected] );

	}

	const handleSubmit = (e) => {

		e.preventDefault();

		let confirmedItems = []

		//do some validation here (on all form fields)
		if( !( mySelectedItems.length > 0 ) ){
			raise('Please make sure you select at least one item to confirm!');
		}

		if( formData.firstname.length < 1 ){
			raise('Please make sure you fill in your name!');
		}

		if( formData.surname.length < 1 ){
			raise('Please make sure you fill in your surname!');
		}

		if( formData.email.length < 1 ){
			raise('Please make sure you fill in your email!');
		}

		let postData = new FormData();
		postData.append("firstname", formData.firstname );
		postData.append("surname", formData.surname );
		postData.append("email", formData.email );
		mySelectedItems.map( (item) => {
			postData.append("items[]", item.key );
		})

		post( postData );

	}

	useEffect( () => {
		if( responseData && responseData.status == 200 ){

			//Test remove items from table
			mySelectedItems.map( (item) => {

				let index;

				switch(item.type){
					case 'event':
						index = data.events.findIndex( (i) => i.bookingItemId == item.itemId );
						data.events.splice(index, 1);
						break;
					case 'hotel':
						index = data.hotels.findIndex( (i) => i.bookingItemId == item.itemId );
						data.hotels.splice(index, 1);
						break;
					default:
						index = data.packages.findIndex( (i) => i.bookingItemId == item.itemId );
						data.packages.splice(index, 1);
						break;
				}
			});

			dispatch({type:"updateCount", key: "finalConfs", count: totals.finalConfs-1});
			setMySelectedItems([]); //reset form
		}
	},[responseData]);

	const PackageOptions = {
		responsive: 'stacked',
		print: false,
		search: false,
		download: false,
		filter: false,
		viewColumns: false,
		pagination: false,
		disableToolbarSelect: true,
		expandableRows:false,
		selectableRowsHeader:false,
		selectableRows: 'multiple',
		selectableRowsOnClick: true,
		rowsPerPageOptions:	[5,10,15,20],
		textLabels: {
			body: {
				noMatch: "Sorry, no matching records found",
				columnHeaderTooltip: column => `Sort for ${column.label}`
			},
			selectedRows: {
				text: ""
			}
		},
		onRowsSelect: (currentRowsSelected, allSelectedRows) => toggleItem( 'superpackage', allSelectedRows )
	}

	const AccomOptions = {
		responsive: 'stacked',
		print: false,
		search: false,
		download: false,
		filter: false,
		viewColumns: false,
		pagination: false,
		disableToolbarSelect: true,
		expandableRows:true,
		renderExpandableRow: (rowData, rowMeta) => {
			let id = rowData[9];

			let currentHotel = data.hotels.find((item)=>id==item.bookingItemId);

			return (
				<tr>
					<td colSpan="8">
						<div className="expanded-content">
							<table className="expanded-table">
								<thead>
								<tr>
									<th scope="col">Date / Time</th>
									<th scope="col">Room Type</th>
									<th scope="col">People</th>
									<th scope="col">Rooms</th>
									<th scope="col">Cost</th>
								</tr>
								</thead>
								<tbody>
								{currentHotel.rooms.map( (room) =>
									<tr key={room.key}>
										<td>{room.date}</td>
										<td>{room.type}</td>
										<td>{room.pax} People</td>
										<td>{room.rooms}</td>
										{room.cost.currencyPrefix ? (
											<td><span dangerouslySetInnerHTML={{ __html: room.cost.currency}} />{room.cost.amount}</td>
										) : (
											<td>{room.cost.amount}<span dangerouslySetInnerHTML={{ __html: room.cost.currency}} /></td>
										)}
									</tr>
								)}
								</tbody>
							</table>
						</div>
					</td>
				</tr>
			)
		},
		selectableRowsHeader:false,
		selectableRows: 'multiple',
		selectableRowsOnClick: true,
		rowsPerPageOptions:	[5,10,15,20],
		textLabels: {
			body: {
				noMatch: "Sorry, no matching records found",
				columnHeaderTooltip: column => `Sort for ${column.label}`
			},
			selectedRows: {
				text: ""
			}
		},
		onRowsSelect: (currentRowsSelected, allSelectedRows) => toggleItem( 'hotel', allSelectedRows )
	};

	const EventOptions = {
		responsive: 'stacked',
		print: false,
		search: false,
		download: false,
		filter: false,
		viewColumns: false,
		pagination: false,
		disableToolbarSelect: true,
		expandableRows:false,
		selectableRowsHeader: false,
		selectableRows: 'multiple',
		selectableRowsOnClick: true,
		rowsPerPageOptions:	[5,10,15,20],
		textLabels: {
			body: {
				noMatch: "Sorry, no matching records found",
				columnHeaderTooltip: column => `Sort for ${column.label}`
			},
			selectedRows: {
				text: ""
			}
		},
		onRowsSelect: (currentRowsSelected, allSelectedRows) => toggleItem( 'event', allSelectedRows )
	};

	return (
		<div>

			<div className="confirmation-wrap">
				<h1 className="section-h1">
					<Icon icon={ICONS.finalConfirmation} viewBox={ICONS.finalConfirmation.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
					Final Confirmation
				</h1>
			</div>

			<div className="inner-content confs">
				{loading ? (
				<div className="spinner-container">
					<div className="spinner-border" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
				) : (

					<div>

						{responseData!==null && responseData.status == 200 ? (
							<div>
								<Alert type={'success'}/>
								<Link to="/">Go back</Link>
							</div>
						) : null }

						{data.events.length || data.hotels.length || data.packages.length ? (
						<div>

							<Alert title="Message from The Eventa Group" message="Please aim to confirm these bookings at least five days prior to departure" type="info" />

							<Tabs>

								<TabList>
									<Tab onClick={handleRemoveItems}>Activity bookings {data.events.length > 0 && <span className="badge">{data.events.length}</span>}</Tab>
									<Tab onClick={handleRemoveItems}>Accommodation Bookings {data.hotels.length > 0 && <span className="badge">{data.hotels.length}</span>}</Tab>
									<Tab onClick={handleRemoveItems}>Superpackage bookings {data.packages.length > 0 && <span className="badge">{data.packages.length}</span>}</Tab>
								</TabList>

								{mySelectedItems.length > 0 ? (
								<div className="finalconfs__container">

									<h3 className="finalconfs__title">Selected: confirmations</h3>

									<div className="finalconfs__ref-holder">
										{mySelectedItems.map( item =>
										<div key={item.key} className="finalconfs__ref-item">
											<div>{item.ref} - {item.name}</div>
											<span remove={item.key} onClick={handleRemoveItem}>x</span>
										</div>
										)}
									</div>

									<form name="finalconfs" className="finalconfs__form" onSubmit={handleSubmit} method="post">
										<div className="form-container form-input-wrap">
											<div className="form-group">
												<label>Your first name</label>
												<input className="form-control" type="text" name="firstname" value={formData.firstname} onChange={(e) => setFormData({...formData, firstname: e.target.value})} placeholder="Please enter your firstname" required />
											</div>
											<div className="form-group">
												<label>Your surname</label>
												<input className="form-control" type="text" name="surname" value={formData.surname} onChange={e => setFormData({...formData, surname: e.target.value})}  placeholder="Please enter your surname" required/>
											</div>
											<div className="form-group">
												<label>Your email</label>
												<input className="form-control" type="text" name="email" value={formData.email} onChange={e => setFormData({...formData, email: e.target.value})}  placeholder="Please enter your email" required/>
											</div>
										</div>
										<button className="btn submit-btn" type="submit">Confirm selected items</button>
									</form>

								</div>
								) : null}

								<TabPanel>
									{data.events.length ? (
										<DataTable title={"Activity bookings"} data={data.events} options={EventOptions} columns={columns}/>
									) : (
										<p>No outstanding final confirmations for activities to display</p>
									)}
								</TabPanel>

								<TabPanel>
									{data.hotels.length ? (
										<DataTable title={"Accommodation bookings"} data={data.hotels} options={AccomOptions} columns={columns}/>
									) : (
										<p>No outstanding final confirmations for hotels to display</p>
									)}
								</TabPanel>
								<TabPanel>
									{data.packages.length ? (
										<DataTable title={"Superpackage bookings"} data={data.packages} options={PackageOptions} columns={columns}/>
									) : (
										<p>No outstanding final confirmations for packages to display</p>
									) }
								</TabPanel>
							</Tabs>
						</div>
						) : (
							<p>No final confirmations need doing this week</p>
						) }
					</div>
				)}
			</div>
		</div>

	)
}

export default Confirmations;
