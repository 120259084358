import React, { Component, useState, useEffect } from "react";
import {useFetch} from '../../shared/hooks';
import { navigate } from "@reach/router";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactTooltip from "react-tooltip";

import { ICONS } from '../shared/icons'
import Icon from '../shared/icon-component'

import ImportantInfoCard from './shared/important-info-card'
import AdditionalInfoCard from './shared/additional-info-card'
import WebsiteContentCard from './shared/website-content-card'
import NormalForm from './shared/normal-form'
import FlagForReviewForm from './shared/flag-review-form'
import ProductStatus from './shared/product-status'

const ProductsHotel = ({id}) => {

	//api call will be as follows
	const [hotel, setHotel] = useState(null)
	const [form, setForm] = useState(null)

	//api call will be as follows
	const { data, loading } = useFetch( `/api/hotel/view/${id}`, {
		hotel:      null,
		form:       null,
	});

	useEffect( () => {
		setHotel(data.hotel);
		setForm(data.form);
	}, [data] );

	const renderForm = () => {
		if( form !==null && form.type.length > 0 ){
			switch( form.type ){
				case 'normal':
					return <NormalForm itemId={hotel.id} itemType="hotel" />
				case 'flagforreview':
					return <FlagForReviewForm itemId={hotel.id} itemType="hotel" />
				default:
					return null;
			}
		}

		return null;

	}

	const goBack = () => {
		navigate(-1);
	}

	return (
		<div className="products-wrap product-item hotel">
			<h1 className="section-h1">
				<Icon icon={ICONS.products} viewBox={ICONS.products.viewBox} width={'46'} height={'46'} color={'#ffffff'} />
				Viewing product hotel page
			</h1>

			<div className="inner-content">

			<div className="row">
				<div className="col-sm-6 col-md-7">
					<a onClick={()=>navigate(-1)} className="back-link"><Icon icon={ICONS.chevronLeft} viewBox={ICONS.chevronLeft.viewBox} width={'14'} height={'14'} color={'#2c3343'} /> Back</a>
				</div>
				<div className="col-sm-6 col-md-5">
					{hotel !== null ? (
						<ProductStatus auditStatusId={hotel.auditStatusId} type="hotel"/>
					) : null}
				</div>
			</div>

				{hotel !==null ? (
					<div className="row">
						<div className="col-12">
							<Tabs>
								<TabList>
									<Tab>Important Info</Tab>
									<Tab>Additional Info</Tab>
									<Tab>Website Content</Tab>
								</TabList>

								<TabPanel>
									<div className="row">
										<div className="col-12">
											<ImportantInfoCard item={hotel} type="hotel"/>
										</div>
									</div>
								</TabPanel>

								<TabPanel>
									<div className="row">
										<div className="col-12">
											<AdditionalInfoCard dataset={hotel.dataset}/>
										</div>
									</div>
								</TabPanel>

								<TabPanel>
									<div className="row">
										<div className="col-12">
											<WebsiteContentCard content={hotel.website} images={hotel.images} usps={hotel.usps}/>
										</div>
									</div>
								</TabPanel>

							</Tabs>

						</div>
					</div>
				) : null }

				<div className="row">
					<div className="col-12">
						{renderForm()}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProductsHotel;
