import React, {useState, useContext} from 'react';
import { store } from "../../../context/store";
import {Link} from "@reach/router";

const BookingForm = ({itemType, isCancellation, showOptions, showGrid, errorFields, doSubmitForm, posted}) => {

	const [globalData, setGlobalData ] = useContext( store );

	const [formData, setFormData] = useState({
		firstname:  globalData.firstname || '',
		surname:    globalData.surname || '',
		email:      globalData.email || '',
		action:     'accept',
		reason:     ''
	})

	//have we been passed any errors from the api?
	let initialErrors = [];
	if( errorFields ){
		initialErrors.push(errorFields);
	}

	const [errors, setErrors] = useState(initialErrors);

	const hasError = (key) => {
		let hasError = errors.find((err)=>err===key );
		return hasError;
	}

	const handleSubmit = (e) => {
		e.preventDefault();

		if( formIsValid() ){
			doSubmitForm(formData);
		}
	}

	const formIsValid = () => {
		//have to set seperate flag as state.errors is async so cant rely on it
		let formErrors = [];

		if( formData.action===null ) {
			formErrors.push('action');
		}

		if( formData.firstname.length < 2 ) {
			formErrors.push("firstname");
		}

		if( formData.surname.length < 2 ) {
			formErrors.push("surname");
		}

		if( formData.email.length < 2 ) {
			formErrors.push("email");
		}

		if( formData.action!=='accept' && formData.reason.length < 2 ){
			formErrors.push("reason");
		}

		setErrors(formErrors);
		return formErrors.length > 0 ? false : true;
	}
	
	return (

		<div className="card card p-2 mt-3 p-md-4">

			<h5 className="card-title">{isCancellation ? "Confirm this cancellation - This has been cancelled. Please complete your details and then press Submit to confirm cancellation" : "Accept or query this request - please complete your details below"}</h5>
			<form name="avail" method="post" onSubmit={handleSubmit}>

				<div className="form-flex-container form-input-wrap">
					<div className="form-group">
						<label>Your first name</label>
						<input className={hasError("firstname") ? "form-control is-invalid" : "form-control"} type="text" name="firstname" value={formData.firstname} onChange={e => setFormData( {...formData, firstname: e.target.value })} placeholder="Please enter your firstname" />
					</div>

					<div className="form-group">
						<label>Your surname</label>
						<input className={hasError("surname") ? "form-control is-invalid" : "form-control"} type="text" name="surname" value={formData.surname} onChange={e => setFormData( {...formData, surname: e.target.value })}  placeholder="Please enter your surname"/>
					</div>

					<div className="form-group">
						<label>Your email</label>
						<input className={hasError("email") ? "form-control is-invalid" : "form-control"} type="text" name="email" value={formData.email} onChange={e => setFormData( {...formData, email: e.target.value })}  placeholder="Please enter your email"/>
					</div>
				</div>

				{showOptions == true ? (
					<div>
						<div className="actions-wrap">
							<p>Please choose how you would like to proceed:</p>
							<button
								type="submit"
								className="btn btn-outline-success mr-4 bg-success text-white"
								disabled={posted ? 'disabled' : null}
								onClick={() => setFormData( {...formData, action: 'accept' })}>
								{posted && formData.action == 'accept' ? (
									<div className="btn-spinner-container">
										<div className="spinner-border" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>
								) : (
									'Accept this booking'
								)}
							</button>
							<button type="button" className={formData.action !== null && formData.action === 'maybe' ? 'btn btn-outline-warning mr-4 bg-warning text-white' : 'btn btn-outline-warning mr-4'} onClick={() => setFormData( {...formData, action: 'maybe' })}>Flag as conditional</button>
							<button type="button" className={formData.action !== null && formData.action === 'decline' ? 'btn btn-outline-danger mr-4 bg-danger text-white' : 'btn btn-outline-danger mr-4'} onClick={() => setFormData( {...formData, action: 'decline' })}>Decline this booking</button>
						</div>

						{formData.action==='maybe'||formData.action==='decline' ? (
							<div className="form-group">
								<label>Reason</label>
								{itemType == 'event' ? (
								<p>If you have other time slots available for this date please list them below.
									If you have multiple slots available please seperate with a comma <b>e.g 13:45, 14:00, 17:00</b>
								</p>
								):null}
								<textarea className={hasError("reason") ? "form-control is-invalid" : "form-control"} name="reason" value={formData.reason} onChange={e => setFormData( {...formData, reason: e.target.value })}></textarea>
							</div>
						) : null}
					</div>
				) : null }

				<button className="btn btn-lg submit-btn" type="submit" disabled={formData.action==='accept' && isCancellation==false} style={formData.action === 'accept' && isCancellation==false ? {opacity: '0', pointerEvents: 'none', height: '0'} : {opacity: '1', pointerEvents: 'all', height: 'auto', transition: '0.3s'}}>
					{posted ? (
						<div className="btn-spinner-container">
							<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					) : (
						'Submit'
					)}
				</button>

			</form>

			<p>Unsure what to do? check out our <Link to="/faqs" >FAQs</Link></p>


		</div>
	)

}

export default BookingForm;
