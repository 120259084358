import React, {Component} from "react";

import { ICONS } from '../../shared/icons'
import Icon from '../../shared/icon-component'
import ReactTooltip from "react-tooltip";

const PriceCard = ({prices, type}) => {

	

	return (
		<div>

			<div className="details card p-4 mt-3 mb-3">
				<div className="details__header">
					<h4 className="details__header--title">Current Prices <span className="info-icon" data-tip data-event='click focus'><Icon icon={ICONS.info} viewBox={ICONS.info.viewBox} width={'25'} height={'25'} color={'#000000'} /></span></h4>
					<ReactTooltip place="top" type="light" globalEventOff='click' effect="solid" aria-haspopup='true'>
						<h5>Current Prices</h5>
						<p>This section shows the current prices available to our sales team. If there is no price in the date range of a booking, then the default price is used. This is marked with a star.</p>
					</ReactTooltip>
				</div>

				{type=='event' ? (
					<div className="flex-container">
						<table className="table table-bordered table-responsive-sm">
							<thead className="thead-dark">
							<tr>
								<th>Date from</th>
								<th>Date to</th>
								<th>Cost</th>
								<th>Price Category</th>
							</tr>
							</thead>
							<tbody>
							{prices.map( (price) =>
								<tr key={`price-${price.id}`}>
									<td>{price.isDefault ? "N/A" : price.from}</td>
									<td>{price.isDefault ? "N/A" : price.to}</td>
									<td>
										{price.cost.currencyPrefix ? (
											<b><span dangerouslySetInnerHTML={{ __html: price.cost.currency}} />{price.cost.amount}</b>
										) : (
											<b>{price.cost.amount}<span dangerouslySetInnerHTML={{ __html: price.cost.currency }} /></b>
										)}
										{price.isPerPerson ? " pp" : " total"}
									</td>
									<td>Default</td>
								</tr>
							)}
							</tbody>
						</table>
					</div>
				):null}

				{type=='superpackage' ?(
					<div className="flex-container">
						<table className="table table-bordered table-responsive-sm">
							<thead className="thead-dark">
							<tr>
								<th>Date from</th>
								<th>Date to</th>
								<th>Cost</th>
								<th>Min</th>
								<th>Max</th>
							</tr>
							</thead>
							<tbody>
							{prices.map( (price) =>
								<tr key={`price-${price.id}`}>
									<td>{price.from}</td>
									<td>{price.to}</td>
									<td>
										{price.cost.currencyPrefix ? (
											<b><span dangerouslySetInnerHTML={{ __html: price.cost.currency}} />{price.cost.amount}</b>
										) : (
											<b>{price.cost.amount}<span dangerouslySetInnerHTML={{ __html: price.cost.currency }} /></b>
										)}
										pp
									</td>
									<td>{price.minimumOccupancy}</td>
									<td>{price.maximumOccupancy}</td>
								</tr>
							)}
							</tbody>
						</table>
					</div>
				):null}

			</div>
		</div>
	);
}

export default PriceCard
